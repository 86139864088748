import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class Drawer10Service {

  private messageSource10 = new BehaviorSubject(true); //false is 'default message'
  currentMessage10 = this.messageSource10.asObservable();

  constructor() { }

  changeMessage10(message10: boolean) {
    this.messageSource10.next(message10)
  }

}