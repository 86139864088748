import { Component,AfterViewInit, OnInit, ViewChild, Pipe, PipeTransform, ɵɵgetInheritedFactory } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import 'rxjs';
import { map } from 'rxjs/operators';
import { observable, of } from 'rxjs';

import { FormGroup, FormBuilder, Validators, FormControl, FormArray, NgForm } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';
import * as firebase from 'firebase';
import * as _ from 'lodash';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class ItemsComponent implements OnInit {

  public pcForm: FormGroup;
  prdkeyid: any;
  prdmastermenu: any;
  prdcollection: any;
  filetot: number;
  prdrate: any;
  favoriteTag: string;
  tags: string[] = ['Hot', 'Spicy', 'Sweet', 'Cool',  'None'];

  enableUpdate: boolean = false;
  
  prdhsncode: any;
  prdgst: any;
  prdigst: any;
  prdunit1: any=1;
  //prdunit2: any="PC(S)";

  prdunit2: string;
  unit2s: string[] = ['PC(S)', 'KG(S)'];

  discparty: any;
  discseasonal: any;
  discregular: any;
  discfestival: any;
  total: any;
 
  itemname: any;
  
  hlprate: any;
  hlpgst: any;

  constructor(private _sanitizer: DomSanitizer, private afs: AngularFirestore, private fb: FormBuilder){ }

  mastermenucollection: any;
 

  ngOnInit() {
    
    this.filetot= 0;

    this.Form();  

    this.favoriteTag=this.tags[4]; // set default radio button checked

    this.prdunit2=this.unit2s[0]; // set default radio button checked

    this.mastermenucollection = this.afs.collection('Mastermenu').snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.prdmastermenu='';

  }

  
  // Reactive form
  Form() {
    this.pcForm = this.fb.group({
      productmastermenu: [''],
      productimages: [''],
      productrate: [''],
      productestimaterate: [''],
      productestimaterate2: [''],
      productqntygodown: [''],
      productqntyshop1: [''],
      productqntyshop2: [''],

      fooditemname: [{value:''}],

      producthsncode: [{value:''}],
      productgst: [{value:''}],
      productigst: [{value:''}],
      productunit1: [{value:''}],
      productunit2: [{value:''}],
      discountparty: [{value:''}],
      discountseasonal: [{value:''}],
      discountregular: [{value:''}],
      discountfestival: [{value:''}],
    })
  }

  // Accessing form control using getters
  get productmastermenu() {
    return this.pcForm.get('productmastermenu');
  }
  get productrate() {
    return this.pcForm.get('productrate');
  }
  get productimages() {
    return this.pcForm.get('productimages');
  }

get fooditemname() {
  return this.pcForm.get('fooditemname');
}

get productgst() {
  return this.pcForm.get('productgst');
}

get productigst() {
  return this.pcForm.get('productigst');
}

get productunit1() {
  return this.pcForm.get('productunit1');
}

get productunit2() {
  return this.pcForm.get('productunit2');
}

get discountparty(){
  return this.pcForm.get('discountparty');
}

get discountseasonal(){
  return this.pcForm.get('discountseasonal');
}

get discountregular(){
  return this.pcForm.get('discountregular');
}

get discountfestival(){
  return this.pcForm.get('discountfestival');
}

  changemastermenu(mm){
    
    this.prdcollection = this.afs.collection('Items', ref => ref.where('mastermenu', "==" , mm)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

this.resetproductdetails();
  }



  helperreset(){
  this.hlprate='';
  this.hlpgst='';
}

gethlporiginalcost(){
  return this.hlprate * (100/(100 + this.hlpgst));
}

gethlpgstcost(){
  return this.hlprate - (this.hlprate * (100/(100 + this.hlpgst)));
}


// Reset form's values
ResetForm() {
  this.urls = [];
  this.filetot= 0;
  this.pcForm.reset();
}


submitProductbuildData() {
  this.add(this.pcForm.value.productid);
  //this.ResetForm();  // Reset form when clicked on reset button
  this.resetproductdetails();
};


add(id) {
   let ids =this.afs.createId();
  this.afs.collection("Items").doc(ids).set({

    0:"0",
    itemid:ids,
    mastermenu:this.prdmastermenu,
      images:this.urls, //images
      rate:isNaN(Number(this.prdrate)) ? 0 : Number(this.prdrate),
      tag:this.favoriteTag,
      

      itemname: (this.itemname!=null) ? (this.itemname.toUpperCase()):'',

      hsncode: (this.prdhsncode!=null) ? (this.prdhsncode.toUpperCase()):'',
      gst:isNaN(Number(this.prdgst)) ? 0 : Number(this.prdgst),
      igst:isNaN(Number(this.prdigst)) ? 0 : Number(this.prdigst),
      unit1: Number(this.prdunit1),
      unit2: this.prdunit2,
      discount:{
        party:isNaN(Number(this.discparty)) ? 0 : Number(this.discparty),
        seasonal:isNaN(Number(this.discseasonal)) ? 0 : Number(this.discseasonal),
        regular:isNaN(Number(this.discregular)) ? 0 : Number(this.discregular),
        festival:isNaN(Number(this.discfestival)) ? 0 : Number(this.discfestival),
      },
      

  }, { merge: true })

    .catch((err) => {
      console.log(err);
    })  
    
}
 
resetproductdetails(){
  this.itemname='';
  this.prdhsncode='';
  this.prdgst='';
  this.prdigst='';
  this.prdrate='';
  this.discregular='';
  this.discseasonal='';
  this.discparty='';
  this.discfestival='';
  this.favoriteTag=this.tags[4];
  this.urls = [];
  this.filetot= 0;

  this.enableUpdate=false;

  this.prdunit1=1;
  this.prdunit2=this.unit2s[0];
}

update(pid) {
  this.afs.collection("Items", ref => ref.where('mastermenu', "==" , this.prdmastermenu)).doc(pid).update({
    images:this.urls, //images
    rate:isNaN(Number(this.prdrate)) ? 0 : Number(this.prdrate),
    tag:this.favoriteTag,
    
    itemname: (this.itemname!=null) ? (this.itemname.toUpperCase()):'',

    hsncode: (this.prdhsncode!=null) ? (this.prdhsncode.toUpperCase()):'',
    gst:isNaN(Number(this.prdgst)) ? 0 : Number(this.prdgst),
    igst:isNaN(Number(this.prdigst)) ? 0 : Number(this.prdigst),
    unit1:Number(this.prdunit1),
    unit2:this.prdunit2,
    discount:{
      "party":isNaN(Number(this.discparty)) ? 0 : Number(this.discparty),
      "seasonal":isNaN(Number(this.discseasonal)) ? 0 : Number(this.discseasonal),
      "regular":isNaN(Number(this.discregular)) ? 0 : Number(this.discregular),
      "festival":isNaN(Number(this.discfestival)) ? 0 : Number(this.discfestival),
    },
  }).then(()=>{
    //this.ResetForm();
    this.resetproductdetails();
    this.enableUpdate=false;
  })

}


read(pd,i) {  
  this.prdkeyid = pd.itemid;
  this.prdmastermenu = pd.mastermenu;
  this.urls = pd.images;
  this.prdrate = pd.rate;
  this.favoriteTag=pd.tag;

  this.itemname=pd.itemname;
  this.prdhsncode=pd.hsncode;
  this.prdgst=pd.gst;
  this.prdigst=pd.igst;
  this.prdunit1=pd.unit1;
  this.prdunit2=pd.unit2;
  this.discseasonal=pd.discount["seasonal"];
  this.discregular=pd.discount["regular"];
  this.discparty=pd.discount["party"];
  this.discfestival=pd.discount["festival"];

  this.filetot = this.urls.length;

}

delete(pd,i) {
  this.prdkeyid = pd.itemid;
  this.afs.collection("Items", ref => ref.where('mastermenu', "==" , this.prdmastermenu)).doc(this.prdkeyid).delete();
}


urls = new Array<string>();
detectFiles(event) {
  this.urls = [];
  let files = event.target.files;
  if (files) {
    for (let file of files) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.urls.push(e.target.result);
        console.log(this.urls);
        this.filetot = this.urls.length;

      }
      reader.readAsDataURL(file);
    }
  }
}

deleteImage(key){
  this.urls.splice(key,1);
  this.filetot = this.urls.length;
  console.log(this.urls);
}


}
