import { Component, ViewChild, OnInit } from '@angular/core';
import { NgModel, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

import 'rxjs';
export interface Item { name: string; rate: number;}
import * as _ from 'lodash';
import * as moment from 'moment';
import * as firebase from 'firebase/app';

import {MatAccordion} from '@angular/material/expansion';

@Component({
  selector: 'app-billentry',
  templateUrl: './billentry.component.html',
  styleUrls: ['./billentry.component.css']
})
export class BillentryComponent implements OnInit {
  @ViewChild(MatAccordion, {static: false}) accordion: MatAccordion;

  date2= moment().format('DD-MM-YYYY');
  showMainContent: Boolean = true;
  menucollection: any = [];
  itemcollection: any = [];
  billitemcollection: any = [];
  billsubscribecollection: any = [];
  selectedMenu: any= null;
  selectedItem: any;
  selectedTable: any= "Table 1";
  tables: any;
  
  //qntycounters
qntycounters: { tid: string, itemid: string, qntycount: number }[]= [] //declare before constructor
//qntycounters
tablecurrentcounter: any ="2";
  kgspcs: { tid: string, kgs:number, pcs:number }[]= [];
  totkgs: number;
  totpcs:number;
  routinebillid: any;


addorreplace(array, item){
  const i=array.findIndex(_item => _item.tid===item.tid && _item.itemid===item.itemid);
  if(i>-1) array[i] =item;
  else array.push(item);
 }



bufferValue=0;
  value=0; //progressbar
  favoriteCustomer: string;
  customers: string[] = ['regular', 'seasonal', 'festival'];
  billoc: string;
  billocations: string[] = ['shop1', 'shop2', 'shop3'];
  modeofpay: string;
  pmodes: string[] = ['CASH', 'CARD', 'UPI PAYMENT'];
  cbilly: string;
  iscbill: string[] = ['yes', 'no'];
 
 taxy:string;
 taxes = [{label:'gst',checked:true}, {label:'igst',checked:false}, {label:'taxfree',checked:false}];
 
   TaxnameGST: string='gst';
   TaxnameIGST: string='0';
   TaxnameTF: string='0';

 tottaxableamt: number;
 totamtwithtax: number;

 sumaftrdisc:number;

 totalamount: number;
 totalrate: number;

 
 summy: { tid: string, gst: number, rate: number, taxableamt: number, amtaftdisc:number }[]= [];
 hello: string;
 custid: any;
 custname: any; 
 custimage: any;
 custtype: any;
 custdiscounttype: any;
 custaddress11: any;
 custaddress12: any;
 custaddress21: any;
 custaddress22: any;
 custcontact: any;
 custgst: any;
 custhonor: any;
 custaddressS1: any;
 custaddressS2: any;
 sameaddr: boolean;
 prdkeyid: any;
 bcbtndisabled: boolean;
 clrbtndisabled: boolean;
 addbtndisabled: boolean;

 billqnty: any;
 prdqnty: any;
 rembtndisabled: boolean;

 arry:{
   code:string;
   qnty:number;
 }[]=[];

 prdkeyidarray:string[]=[];
 billqntyarray:number[]=[];

 totbills:number;
 crcollection: any;


 allroutineidarry: { id: number, location: string, status: string }[]=[];
 completedroutineidarry: { id: number, location: string, status: string }[]=[];
 uncompletedroutineidarry: { id: number, location: string, status: string }[]=[];
     eventdate: string;
     crd: number=0;
     dbt: number=0;

     custqnty:number=0;
     b:{ id: string }[]=[];

     billid : any;
     billrefno : any;

     currentbillid:any;

 updateCheckedOptions(index, tax)  {
   
  tax.checked = !tax.checked;
  //----newly added--------
  tax.checked=true;
  //----newly added--------
  console.log(index,tax.checked);

  this.updateradiobuttons();

  if(tax.checked==true)
  {
  if(index==0){this.TaxnameGST='gst';this.TaxnameIGST='0';this.TaxnameTF='0';this.taxes = [{label:'gst',checked:true}, {label:'igst',checked:false}, {label:'taxfree',checked:false}];}
  if(index==1){this.TaxnameIGST='igst';this.TaxnameGST='0';this.TaxnameTF='0';this.taxes = [{label:'gst',checked:false}, {label:'igst',checked:true}, {label:'taxfree',checked:false}];}
  if(index==2){this.TaxnameTF='taxfree';this.TaxnameGST='0';this.TaxnameIGST='0';this.taxes = [{label:'gst',checked:false}, {label:'igst',checked:false}, {label:'taxfree',checked:true}];}
  console.log(this.taxes);
  }
  if(tax.checked==false){
    console.log(index,tax);

  }

  if(this.taxy.toUpperCase().slice(0, 1)=="G" || this.taxy.toUpperCase().slice(0, 1)=="I")
  this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
      this.billrefno="A"+doc.data().countG;
  })
  if(this.taxy.toUpperCase().slice(0, 1)=="T")
  this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
      this.billrefno="B"+doc.data().countR;
  })
}
public BPSForm: FormGroup;

custcollection: any;
cust: any;
ar: number=0;
  constructor(private afs: AngularFirestore, public fb: FormBuilder) {
   
    this.favoriteCustomer=this.customers[0]; // set default radio button checked
    this.billoc=this.billocations[0]; // set default radio button checked
    this.taxy='gst' // set default radio button checked
    this.cbilly='no' // set default radio button checked
    this.billitemcollection='';
    this.billsubscribecollection='';
    this.modeofpay='CASH'; // set default radio button checked



    this.tables = [
      { id: 91, name: "TAKE AWAY 1" },
      { id: 92, name: "TAKE AWAY 2" },
      { id: 1, name: "TABLE 1" },
      { id: 2, name: "TABLE 2" },
      { id: 3, name: "TABLE 3" },
      { id: 4, name: "TABLE 4" },
      { id: 5, name: "TABLE 5" },
      { id: 6, name: "TABLE 6" },
      { id: 7, name: "TABLE 7" },
      { id: 8, name: "TABLE 8" },
      { id: 9, name: "TABLE 9" },
      { id: 10, name: "TABLE 10" },
      // { id: 100, name: "ORDER" },
    ];

    this.menucollection =   this.afs.collection('Mastermenu').snapshotChanges()      
    .pipe( 
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.selectedtablechange("TABLE 1");
    }

  ngAfterViewInit() {
    // var el =document.getElementsByClassName('rightsidebar') as HTMLCollectionOf<HTMLElement>;
    // el[0].style.cssText="max-width: 950px !IMPORTANT; width: 950px !IMPORTANT;"
    //console.log("ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ")
    this.eventdate=moment().format('DD-MM-YYYY hh:mm:ss A')

}

    async ngOnInit() {

      //newly added
      this.tottaxableamt=0;
      this.sumaftrdisc=0;
      this.totamtwithtax=0;
      this.kgspcs=[];
      this.totkgs=0;
      this.totpcs=0;

    this.totalamount=0;

      this.ar=0;
      this.cbilly='no';
      this.modeofpay='CASH'; 

      //newly added

      this.btnsdisabled();

      this.sameaddr=true;
  
    this.custid='';
    this.custname='';
    this.custimage='';
    this.custtype='';
    this.custdiscounttype='';
    this.custaddress11='';
    this.custaddress12='';
    this.custaddress21='';
    this.custaddress22='';
    this.custcontact='';
    this.custgst='';
    this.custhonor='';
    this.custaddressS1='';
    this.custaddressS2='';
  
    this.Form();

    this.tottaxableamt=0;
    this.totamtwithtax=0;
  
    this.sumaftrdisc=0;
    this.totalamount=0;

if(this.taxy.toUpperCase().slice(0, 1)=="G" || this.taxy.toUpperCase().slice(0, 1)=="I")
  this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
      this.billrefno="A"+doc.data().countG;
  })
  if(this.taxy.toUpperCase().slice(0, 1)=="T")
  this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
      this.billrefno="B"+doc.data().countR;
  })


let document = await firebase.firestore().collection("Bills").doc(this.date2).get();
if (document && document.exists) {
  // await document.ref.update({
  //   updated: new Date().toISOString()
  // });
}
else {
  await document.ref.set({
    currbillid : 1,
    currbillfullid : this.billoc.toUpperCase().slice(0,1)+this.billoc.toUpperCase().slice(-1)+this.favoriteCustomer.toUpperCase().slice(0,1)+this.taxy.toUpperCase().slice(0,1)+'1',
    totalbills : 0,
    routebillid: 1,
    date2 : this.date2,
  }, { merge: true });
  
}

this.crcollection = this.afs.collection('Bills', ref => ref.where('date2', '==', this.date2)).snapshotChanges()
.pipe( 
  map(actions => actions.map(a => a.payload.doc.data()))
);

this.crcollection.subscribe(() => {
  this.afs.collection('Bills', ref => ref.where('date2', '==', this.date2)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(docy => {
      this.currentbillid = docy.data().currbillid;
    })
  })

  //newly added
  if(this.taxy.toUpperCase().slice(0, 1)=="G" || this.taxy.toUpperCase().slice(0, 1)=="I")
    this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
        this.billrefno="A"+doc.data().countG;
    })
    if(this.taxy.toUpperCase().slice(0, 1)=="T")
    this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
        this.billrefno="B"+doc.data().countR;
    })
  //newly added
  })



this.afs.collection('Bills', ref => ref.where('date2', '==', this.date2)).get().toPromise().then(querySnapshot => {
  querySnapshot.forEach(docy => {
    this.currentbillid = docy.data().currbillid;
    this.routinebillid = docy.data().routebillid;
    this.afs.collection('Bills').doc(this.date2).set(
      {
        routebillid: this.routinebillid + 1,
      },
      { merge: true }
    )
  })
}).then(()=>{
//newly added
if(this.taxy.toUpperCase().slice(0, 1)=="G" || this.taxy.toUpperCase().slice(0, 1)=="I")
this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
    this.billrefno="A"+doc.data().countG;
})
if(this.taxy.toUpperCase().slice(0, 1)=="T")
this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
    this.billrefno="B"+doc.data().countR;
})
//newly added
  })
.then(()=>{

  this.afs.collection('Bills').doc(this.date2).collection('--DUMMIES--').doc(String(this.currentbillid)).set(
    {
      currentbillid: this.currentbillid,
      currbillfullid: this.billoc.toUpperCase().slice(0, 1) + this.billoc.toUpperCase().slice(-1) + this.favoriteCustomer.toUpperCase().slice(0, 1) + this.taxy.toUpperCase().slice(0, 1) + this.currentbillid,
      //currbillfullid:this.hello,
      routinebillid:this.routinebillid,
      location: this.billoc,
      status:'created'
    },
     { merge: true }
  )
  //console.log("aaaaaaaa");

}).then(()=>{

  console.log("bbbroutineID"+this.routinebillid)
  this.afs.collection('Bills').doc(this.date2).collection('--DUMMIES--', ref => ref.where('routinebillid', '==', this.routinebillid)).get().toPromise().then(querySnapshot => {
  querySnapshot.forEach(doc => {
  
  this.currentbillid=doc.data().currentbillid;
  console.log(":::::::::::::::::::::::currbillid::::::"+this.currentbillid);
  })
  })
}).then(()=>{

  this.value = 100; //progressbar
  this.delay(1000).then(any=>{
    this.value = 0; //progressbar
  
      this.btnsenabled();
  });
  })

}


Csearch(ph){
  this.afs.collection('Customers', ref => ref.where('customerContact', '==' , String(ph))).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(docy => {
  this.custid=docy.data().KEYID;
  this.custname=docy.data().customerName;
  // this.custimage=docy.data().customerImage;
  // this.custtype=docy.data().customerType;
  // this.custdiscounttype=docy.data().customerDiscountType;
  this.custaddress11=docy.data().customerAddress11;
  this.custaddress12=docy.data().customerAddress12;
  this.custaddress21=docy.data().customerAddress21;
  this.custaddress22=docy.data().customerAddress22;
  this.custcontact=docy.data().customerContact;
  this.custgst=docy.data().customerGst;
  this.custhonor=docy.data().customerHonor;
  this.sameaddr=true;
  console.log("SA"+this.sameaddr)
  this.custaddressS1=this.custaddress11;
  this.custaddressS2=this.custaddress12;
  this.favoriteCustomer= (docy.data().customerDiscountType != undefined) ? docy.data().customerDiscountType : 'regular';
  console.log("FC"+this.favoriteCustomer);
    })
  });
}

shipaddress(event){
console.log(event.checked);
if(event.checked==true){
  this.custaddressS1=this.custaddress11;
  this.custaddressS2=this.custaddress12;
}
if(event.checked==false){
  this.custaddressS1=this.custaddress21;
  this.custaddressS2=this.custaddress22;
}
}

updateradiobuttons(){
  this.afs.collection('Bills', ref => ref.where('date2', '==' , this.date2)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(docy => {
this.currentbillid=docy.data().currbillid;
  this.afs.collection('Bills').doc(this.date2).collection('--DUMMIES--').doc(String(this.currentbillid)).update({
    currentbillid: this.currentbillid,
    currbillfullid: this.billoc.toUpperCase().slice(0,1)+this.billoc.toUpperCase().slice(-1)+this.favoriteCustomer.toUpperCase().slice(0,1)+this.taxy.toUpperCase().slice(0,1)+docy.data().currbillid,
  })
})
  })
  //newly added
if(this.taxy.toUpperCase().slice(0, 1)=="G" || this.taxy.toUpperCase().slice(0, 1)=="I")
this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
    this.billrefno="A"+doc.data().countG;
})
if(this.taxy.toUpperCase().slice(0, 1)=="T")
this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
    this.billrefno="B"+doc.data().countR;
})
//newly added
}

updateBillCheckedOptions(i, billocation)
{
  this.updateradiobuttons();
}
updateCustCheckedOptions(i, customer)
{
  this.updateradiobuttons();
}


// Reactive particular form
Form() {
  this.BPSForm = this.fb.group({
    barcodeid: [''],
    credit: [0],
    debit: [0]
  })
}
// Accessing form control using getters
get barcodeid() {
  return this.BPSForm.get('barcodeid');
}
get credit() {
  return this.BPSForm.get('credit');
}
get debit() {
  return this.BPSForm.get('debit');
}

prcollection: any;

btnsdisabled(){
this.bcbtndisabled=true;
this.clrbtndisabled=true;
this.addbtndisabled=true;
this.rembtndisabled=true;
}
btnsenabled(){
this.bcbtndisabled=false;
this.clrbtndisabled=false;
this.addbtndisabled=false; 
this.rembtndisabled=false;

}


loaditem(){
  this.itemcollection =   this.afs.collection('Items', ref => ref.where('mastermenu', '==', this.selectedMenu).orderBy('itemname','asc')).snapshotChanges()      
  .pipe( 
    map(actions => actions.map(a => a.payload.doc.data()))
  );
}

async delay(ms: number) {
  await new Promise<void>(resolve => setTimeout(()=>resolve(), ms)).then(()=>{console.log("fired");});
}

upcount(itmid: any,itname: any, qnty: any){
  this.btnsdisabled();

  this.afs.collection('Tables').doc(this.selectedTable).collection(this.tablecurrentcounter.toString()).get().toPromise().then(querySnapshot => {

      querySnapshot.forEach(doc => {
        if (doc.data().itemid == itmid) {
          if(!this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid)){
            //qntycounters insert
            this.addorreplace(this.qntycounters,{'tid': this.selectedTable,'itemid':itmid, qntycount: qnty + doc.data().unit1});
            //console.log(JSON.stringify(this.qntycounters));
            //qntycounters insert
              }
            else {
            //qntycounters update
             this.addorreplace(this.qntycounters,{'tid': this.selectedTable,'itemid':itmid, 'qntycount':this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount + doc.data().unit1});
            //console.log(JSON.stringify(this.qntycounters));
            //qntycounters update
            }

  this.afs.collection('Tables').doc(this.selectedTable).collection(this.tablecurrentcounter.toString()).doc(itmid).set(
    {
      qnty : this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount, //qnty
      rate : ((this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount) * (doc.data().rate / doc.data().qnty)),
      amountafterdiscount:((this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount) * (doc.data().amountafterdiscount / doc.data().qnty)),
    },
     {merge: true}
).then(()=>{
  this.btnsenabled();
})
  .catch((err) => {
    //console.log(err);
  })
        }
      })
      
    })
  
}

downcount(itmid: any,itname: any, qnty: any){
  this.btnsdisabled();

  this.afs.collection('Tables').doc(this.selectedTable).collection(this.tablecurrentcounter.toString()).get().toPromise().then(querySnapshot => {

      querySnapshot.forEach(doc => {
        if (doc.data().itemid == itmid) {
          if(!this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid)){
            //qntycounters insert
            this.addorreplace(this.qntycounters,{'tid': this.selectedTable,'itemid':itmid, qntycount: qnty - doc.data().unit1});
            //console.log(JSON.stringify(this.qntycounters));
            //qntycounters insert
              }
            else {
            //qntycounters update             
             this.addorreplace(this.qntycounters,{'tid': this.selectedTable,'itemid':itmid, 'qntycount':this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount - doc.data().unit1});
            //console.log(JSON.stringify(this.qntycounters));
            //qntycounters update
            }

  this.afs.collection('Tables').doc(this.selectedTable).collection(this.tablecurrentcounter.toString()).doc(itmid).set(
    {
      qnty : this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount, //qnty
      rate : ((this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount) * (doc.data().rate / doc.data().qnty)),
      amountafterdiscount:((this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount) * (doc.data().amountafterdiscount / doc.data().qnty)),
    },
     {merge: true}
).then(()=>{
  this.btnsenabled();
})
  .catch((err) => {
    //console.log(err);
  })
        }
      })
      
    })
}


customqnty(itmid: any,cstmqnty:number){
 
  this.btnsdisabled();

  this.afs.collection('Tables').doc(this.selectedTable).collection(this.tablecurrentcounter.toString()).get().toPromise().then(querySnapshot => {

    querySnapshot.forEach(doc => {
      if (doc.data().itemid == itmid) {
        if(!this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid)){
          //qntycounters insert
          this.addorreplace(this.qntycounters,{'tid': this.selectedTable,'itemid':itmid, 'qntycount': Number(cstmqnty) });
          //console.log(JSON.stringify(this.qntycounters));
          //qntycounters insert
            }
          else {
          //qntycounters update
          this.addorreplace(this.qntycounters,{'tid': this.selectedTable,'itemid':itmid, 'qntycount':this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount + (Number(cstmqnty))});
          //console.log(JSON.stringify(this.qntycounters));
          //qntycounters update
          }

this.afs.collection('Tables').doc(this.selectedTable).collection(this.tablecurrentcounter.toString()).doc(itmid).set(
  {
    qnty : this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount, //qnty
    rate : ((this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount) * (doc.data().rate / doc.data().qnty)),
    amountafterdiscount:((this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount) * (doc.data().amountafterdiscount / doc.data().qnty)),
  },
   {merge: true}
).then(()=>{
  this.btnsenabled();
})
.catch((err) => {
  //console.log(err);
})
      }
    })
    
  })

}

removebillitem(itmid,itname){
 // this.btnsdisabled();

  this.afs.collection('Tables').doc(this.selectedTable).collection(this.tablecurrentcounter.toString()).doc(itmid).delete()
  this.qntycounters=this.qntycounters.filter(item=>item.tid!=this.selectedTable.toString());

  if(this.qntycounters.length===0){
    this.afs.collection('Tables').doc(this.selectedTable.toString()).set(
      {
        status : "free",
      },
       {merge: true}
    )
  }
}

 ShowHideButton() {
  this.showMainContent = this.showMainContent ? false : true;     
}

callbeforeselectedtablechange(){  
  // console.log("FROM:"+this.selectedTable);
  this.afs.collection('Tables').doc(this.selectedTable.toString()).update(
    {
      discount: this.favoriteCustomer,
      taxtype: this.taxy
    }
  )
  // console.log(this.taxy);
}
   selectedtablechange(tb: any){

    //newly added
    this.totalamount=0.00;
    this.sumaftrdisc=0.00;
    this.tottaxableamt=0.00;
    this.totkgs=0.00;
    this.totpcs=0.00;
    //newly added


    this.selectedTable=tb;

     this.tablestatus(tb);
   }

   tablestatus(tb){
    this.afs.collection('Tables').doc(this.selectedTable).get().toPromise().then(doc=>{
    this.favoriteCustomer=doc.data().discount; 
    //this.billoc=doc.data().billocation;
    this.taxy=doc.data().taxtype; 
    this.cbilly='no';
    }).then(()=>{

     // console.log("TO:"+this.selectedTable);
     // console.log(this.taxy);

   if(this.taxy=='gst'){this.TaxnameGST='gst';this.TaxnameIGST='0';this.TaxnameTF='0';}
   if(this.taxy=='igst'){this.TaxnameIGST='igst';this.TaxnameGST='0';this.TaxnameTF='0';}
   if(this.taxy=='taxfree'){this.TaxnameTF='taxfree';this.TaxnameGST='0';this.TaxnameIGST='0';}
   
   if(this.taxy.toUpperCase().slice(0, 1)=="G" || this.taxy.toUpperCase().slice(0, 1)=="I")
   this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
       this.billrefno="A"+doc.data().countG;
   })
   if(this.taxy.toUpperCase().slice(0, 1)=="T")
   this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
       this.billrefno="B"+doc.data().countR;
   })

      this.loadbill(tb);

    })
   }
   
   menuclicked(mn: any) {
    this.selectedMenu = mn;
    this.loaditem();
    this.ShowHideButton();
  }

  itemclicked(itname: any, itmid: any) {

    this.selectedItem = itname;
    //console.log(itname, itmid);

    this.loadbillbasiccontent(itname, itmid);
    //this.ShowHideButton();

  }

loadbill(tbname: any){
  //console.log(tbname);
  this.afs.collection('Tables').doc(tbname).get().toPromise().then(  doc => {
    this.tablecurrentcounter=doc.data().counter.toString();
}).then(()=>{
this.billitemcollection = this.afs.collection('Tables').doc(tbname).collection(this.tablecurrentcounter).snapshotChanges()
.pipe( 
  map(actions => actions.map(a => a.payload.doc.data()))
);
//console.log(this.billitemcollection);
})
.then(()=>{
  this.billsubscribe();
})
}

   loadbillbasiccontent(itname,itmid){

    //console.log("tablecurrentcounter>>>"+ this.tablecurrentcounter);
    this.afs.collection('Tables').doc(this.selectedTable.toString()).set(
      {
        status : "dining",
      },
       {merge: true}
    )

    this.afs.collection('Tables').doc(this.selectedTable).collection(this.tablecurrentcounter.toString()).get().toPromise().then(sub=>{
if(sub.docs.length>0)
{
  console.log("exists");

  sub.forEach(docy => {
    if (docy.data().itemid == itmid) {
    this.addorreplace(this.qntycounters,{'tid': this.selectedTable,'itemid':docy.data().itemid, qntycount: docy.data().qnty});
    console.log(JSON.stringify(this.qntycounters));
    }
  })

        this.afs.collection('Items', ref => ref.where('itemid', '==', itmid)).get().toPromise().then(querySnapshot => {
          querySnapshot.forEach(doc => {

           if(!this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid)){
              console.log("exists if called")//existed table existed item fresh click

              
    this.afs.collection('Tables').doc(this.selectedTable).collection(this.tablecurrentcounter.toString()).doc(itmid).set(
      {
                   itemid: doc.data().itemid,
                itemname: doc.data().itemname,
                mastermenu: doc.data().mastermenu,
                rate : doc.data().rate,
                qnty : doc.data().unit1,

                unit1: doc.data().unit1,
                unit2: doc.data().unit2,

                cgst: doc.data().gst/2,
                igst: doc.data().igst,
                amountafterdiscount: (doc.data().rate - (doc.data().rate * ((doc.data().discount[this.favoriteCustomer])/100))),
                status: "ordered",
                status2: (this.selectedTable == "TAKE AWAY 1" || this.selectedTable == "TAKE AWAY 2") ? "takeaway" : "dinein"
                },
              { merge: true }
            )
               //qntycounters insert
          this.addorreplace(this.qntycounters,{'tid': this.selectedTable,'itemid':itmid, qntycount: doc.data().unit1});
          console.log(JSON.stringify(this.qntycounters));
          //qntycounters insert
              }
            
            else{
              console.log("exists else called")//existed table existed item second and after click
              
              //qntycounters update
               this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount + doc.data().unit1;
              console.log(JSON.stringify(this.qntycounters));
              //qntycounters update
  
              sub.forEach(docy => {
                if (docy.data().itemid == itmid) {
                  console.log("ify called");
            this.afs.collection('Tables').doc(this.selectedTable).collection(this.tablecurrentcounter.toString()).doc(itmid).set(
              {
              qnty : this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount, //qnty
      rate : (((this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount)) * (docy.data().rate / docy.data().qnty)),
      amountafterdiscount:((this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount) * (docy.data().amountafterdiscount / docy.data().qnty)),
            },
            { merge: true }
          )
                }
          })

            }



      })
    })
      
}
else{
  console.log("!exists"); //empty table

        this.afs.collection('Items', ref => ref.where('itemid', '==', itmid)).get().toPromise().then(querySnapshot => {
          querySnapshot.forEach(doc => {

            if(!this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid)){
              console.log("!exists if called") //fresh table fresh item fresh click

              this.afs.collection('Tables').doc(this.selectedTable).collection(this.tablecurrentcounter.toString()).doc(itmid).set(
                {
                  itemid: doc.data().itemid,
                  itemname: doc.data().itemname,
                  mastermenu: doc.data().mastermenu,
                  rate: doc.data().rate,
                  qnty: doc.data().unit1,
                  unit1: doc.data().unit1,
                  unit2: doc.data().unit2,
  
                  cgst: doc.data().gst/2,
                  igst: doc.data().igst,
                  amountafterdiscount: (doc.data().rate - (doc.data().rate * ((doc.data().discount[this.favoriteCustomer])/100))),
                  status: "ordered",
                  status2: (this.selectedTable == "TAKE AWAY 1" || this.selectedTable == "TAKE AWAY 2") ? "takeaway" : "dinein"
                },
                { merge: true }
              )
              

          //qntycounters insert
          this.addorreplace(this.qntycounters,{'tid': this.selectedTable,'itemid':itmid, qntycount: doc.data().unit1});
          console.log(JSON.stringify(this.qntycounters));
          //qntycounters insert

            }

            
            else{
              console.log("!exists else called") //not possible

            }
      })
    })




}
    })
    .then(()=>{
      this.billsubscribe();
    })

    .catch(function(error) {
      //console.log("Error getting document:", error);
  });
   }


   billsubscribe(){  

    // this.billsubscribecollection = this.afs.collection('Tables', ref => ref.where('tableid', '==', this.selectedTable.toString())).snapshotChanges()
    // .pipe( 
    //   map(actions => actions.map(a => a.payload.doc.data()))
    // );
    this.billitemcollection = this.afs.collection('Tables').doc(this.selectedTable.toString()).collection(this.tablecurrentcounter).snapshotChanges()
    .pipe( 
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.billitemcollection.subscribe((posts) => {
    

//newly added
      this.kgspcs=_(posts).groupBy('unit2')
    .map((objs, key) => {

        return {
      'unit2': key,
      'kgs':(key == "KG(S)") ? _.sumBy(objs, 'qnty') : 0,
      'pcs':(key == "PC(S)") ? _.sumBy(objs, 'qnty') : 0,
        }
    })
    .value();
    this.totkgs=this.kgspcs.reduce((sum, item) => sum + item.kgs, 0)
    this.totpcs=this.kgspcs.reduce((sum, item) => sum + item.pcs, 0)
    //console.log("this.totkgs"+this.totkgs)
    //console.log("this.totpcs"+this.totpcs)
//newly added



      //console.log("posty:"+posts); //This is the array of posts, not an observable
    
      if(this.TaxnameGST != '0'){
      this.summy=_(posts).groupBy('cgst')
        .map((objs, key) => {
            return {
                'cgst': key,
                // 'rate': _.sumBy(objs, 'rate') * _.maxBy(objs, 'qnty'),
          'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
          'gstp':(key*2),
          'cgstp':(parseFloat(key)),
          'cgsta':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
          'sgstp':(parseFloat(key)),
          'sgsta':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
          'igstp':(parseFloat('0')),
          'igsta':(parseFloat('0.00')),
          'taxableamt':2 * ((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
            }
        })
        .value();
    
        this.tottaxableamt=this.summy.reduce((sum, item) => sum + item.taxableamt, 0)
        this.totamtwithtax=this.summy.reduce((sum, item) => sum + item.amtaftdisc, 0) + this.tottaxableamt;
    
        this.sumaftrdisc=this.summy.reduce((sum, item) => sum + item.amtaftdisc, 0);

        //console.log("SUMMYYY:"+JSON.stringify(this.summy));
        //console.log("tottaxableamt:"+this.tottaxableamt);
        //console.log("totamtwithtax:"+this.totamtwithtax);
        //console.log("sumaftrdisc:"+this.sumaftrdisc);
      }
    
      if(this.TaxnameIGST != '0'){
        this.summy=_(posts).groupBy('igst')
          .map((objs, key) => {
              return {
                  'igst': key,
                  // 'rate': _.sumBy(objs, 'rate') * _.maxBy(objs, 'qnty'),
                  
                  'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
                  'gstp':(0),
                  'cgstp':(parseFloat('0')),
                  'cgsta':(parseFloat('0.00')),
                  'sgstp':(parseFloat('0')),
                  'sgsta':(parseFloat('0.00')),
                  'igstp':(parseFloat(key)),
                  'igsta':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
                  'taxableamt':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
                   }
          })
          .value();
    
          this.tottaxableamt=this.summy.reduce((sum, item) => sum + item.taxableamt, 0)
          this.totamtwithtax=this.summy.reduce((sum, item) => sum + item.amtaftdisc, 0) + this.tottaxableamt;
    
    this.sumaftrdisc=this.summy.reduce((sum, item) => sum + item.amtaftdisc, 0);
    
          //console.log("SUMMYYY:"+JSON.stringify(this.summy));
        }
    
    
        if(this.TaxnameTF != '0'){
          this.summy=_(posts).groupBy('taxfree')
            .map((objs, key) => {
                return {
                    'taxfree': key,
                    // 'rate': _.sumBy(objs, 'rate') * _.maxBy(objs, 'qnty'),
                    
                    'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
                    'gstp':(0),
                    'cgstp':(parseFloat('0')),
                    'cgsta':(parseFloat('0.00')),
                    'sgstp':(parseFloat('0')),
                    'sgsta':(parseFloat('0.00')),
                    'igstp':(parseFloat('0.00')),
                    'igsta':(parseFloat('0.00')),
                    'taxableamt':(parseFloat('0.00')),
                     }
            })
            .value();
    
            this.tottaxableamt=this.summy.reduce((sum, item) => sum + item.taxableamt, 0)
            this.totamtwithtax=this.summy.reduce((sum, item) => sum + item.amtaftdisc, 0) + this.tottaxableamt;
    
            this.sumaftrdisc=this.summy.reduce((sum, item) => sum + item.amtaftdisc, 0)
    
            //console.log("SUMMYYY:"+JSON.stringify(this.summy));
          }
    
    
      //console.log("posts:"+posts); //This is the array of posts, not an observable
    
      let ratetotal = 0;
      let amounttotal = 0;
    //console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+posts.length);
    for (var i = 0; i < posts.length; i++) {
        if (posts[i].rate) {
          //console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+posts[i].rate);
            ratetotal += posts[i].rate;
            this.totalrate = ratetotal;
    
            // amounttotal += (posts[i].rate * posts[i].qnty);
            amounttotal += (posts[i].rate);
            this.totalamount = amounttotal;
        }
    }
    //console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+ratetotal);
    return [ratetotal,amounttotal];
    
    });   
    
   }



  async submitbtn(){

    //----copy collection------
//     let srcCollection = await this.afs.collection('Tables').doc('TABLE 1').collection('1');
//     let destCollection = this.afs.collection('Bills').doc('20-04-2021').collection('4');
  
//     srcCollection.get().toPromise().then(query => {
//   query.forEach(function(doc) {
//     destCollection.doc(doc.data().itemid).set(doc.data());
//   });
// });
   //----copy collection------




    //------newly added------
this.btnsdisabled();
//------newly added------

  this.hello=document.getElementById('billidlabel').textContent;

      let srcCollection = await this.afs.collection('Tables').doc(this.selectedTable.toString()).collection(this.tablecurrentcounter.toString());
    let destCollection = this.afs.collection('Bills').doc(this.date2).collection(String(this.currentbillid));
  
    srcCollection.get().toPromise().then(query => {
  query.forEach(function(doc) {
    destCollection.doc(doc.data().itemid).set(doc.data());
    // destCollection.doc(doc.data().itemid).update({
    //   amountafterdiscount: (2 * doc.data().qnty)
    // });
  });
});





  this.afs.collection('Bills', ref => ref.where('date2', '==', this.date2)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(docy => {
this.totbills=docy.data().totalbills;
  this.afs.collection('Bills').doc(this.date2).set(
    {
      totalbills : this.totbills + 1,
      currbillid : this.currentbillid + 1,
      billsid:firebase.firestore.FieldValue.arrayUnion(this.hello),
      billsrefno:firebase.firestore.FieldValue.arrayUnion(this.billrefno)
    },
    {merge: true}
 )

  })
  
})

.then(() => {
  
  if(this.taxy.toUpperCase().slice(0, 1)=="G" || this.taxy.toUpperCase().slice(0, 1)=="I"){
    this.afs.collection('OverallBillCounter').doc('Counter').set(
      {
        countG : firebase.firestore.FieldValue.increment(1),
      },
       {merge: true}
  )

    }
    if(this.taxy.toUpperCase().slice(0, 1)=="T"){
      this.afs.collection('OverallBillCounter').doc('Counter').set(
        {
          countR : firebase.firestore.FieldValue.increment(1),
        },
         {merge: true}
    )
      }
      
  })
  .then(()=>{
//newly added
if(this.taxy.toUpperCase().slice(0, 1)=="G" || this.taxy.toUpperCase().slice(0, 1)=="I")
this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
    this.billrefno="A"+doc.data().countG;
})
if(this.taxy.toUpperCase().slice(0, 1)=="T")
this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
    this.billrefno="B"+doc.data().countR;
})
//newly added
}).then(()=>{
  
  this.afs.collection('Bills').doc(this.date2).collection('--Id--').doc(String(this.currentbillid)).set(
    {
      currentbillid: this.currentbillid,
      currbillfullid: this.billoc.toUpperCase().slice(0, 1) + this.billoc.toUpperCase().slice(-1) + this.favoriteCustomer.toUpperCase().slice(0, 1) + this.taxy.toUpperCase().slice(0, 1) + this.currentbillid,
      //currbillfullid:this.hello,
      routinebillid:this.routinebillid,
      location: this.billoc,
      status:'completed'
    },
     { merge: true }
  )
  //console.log("aaaaaaaa");

  this.afs.collection('Bills').doc(this.date2).collection('--DUMMIES--').doc(String(this.currentbillid)).set(
    {
      currentbillid: this.currentbillid,
      currbillfullid: this.billoc.toUpperCase().slice(0, 1) + this.billoc.toUpperCase().slice(-1) + this.favoriteCustomer.toUpperCase().slice(0, 1) + this.taxy.toUpperCase().slice(0, 1) + this.currentbillid,
      //currbillfullid:this.hello,
      routinebillid:this.routinebillid,
      location: this.billoc,
    },
     { merge: true }
  )

})


.then(() => {
  this.afs.collection('Bills').doc(this.date2).collection('--stats--').doc(String(this.currentbillid)).set(
    {
      // billid: this.currentbillid,
      // taxtype : this.taxy,
      // location: this.billoc,
      // customertype: this.favoriteCustomer
      customerId:this.custid,
      customerHonor:this.custhonor,
      customerName:this.custname,
      customerImage:this.custimage,
      customerType:this.custtype,
      customerDiscountType:this.custdiscounttype,
      customerAddress11:this.custaddress11,
      customerAddress12:this.custaddress12,
      customerAddress21:this.custaddressS1,
      customerAddress22:this.custaddressS2,
      customerContact:this.custcontact,
      customerGst:this.custgst,
      billrefno:this.billrefno,
      paymentmode: this.modeofpay,
      cbill: this.cbilly,
      date:this.eventdate,
    },
     {merge: true}
  )
})

.then(() => {
  if(this.crd !=0 || this.dbt != 0){
    let ids =this.afs.createId();
this.afs.collection("Ledger").doc(ids).set({
  book: "SALES",
  KEYID: ids,
  date: firebase.firestore.FieldValue.serverTimestamp(),
  date2: this.date2,
  part:this.custid+"+"+this.custname+"+customer",
  particulartype:"customer",
  // cust: {
  //   customerId:this.custid,
  //   customerHonor:this.custhonor,
  //   customerName:this.custname,
  //   customerAddress11:this.custaddress11,
  //   customerAddress12:this.custaddress12,
  //   customerAddress21:this.custaddressS1,
  //   customerAddress22:this.custaddressS2,
  //   customerContact:this.custcontact,
  //   customerGst:this.custgst,
  //   billrefno:this.billrefno
  // },
  debit: this.dbt,
  credit: this.crd,
  description: this.billrefno+ "/" +this.billoc.toUpperCase().slice(0, 1) + this.billoc.toUpperCase().slice(-1) + this.favoriteCustomer.toUpperCase().slice(0, 1) + this.taxy.toUpperCase().slice(0, 1) + this.currentbillid,
  tag: "BILLS",
  name: this.custname
})
  .catch((err) => {
    console.log(err);
  })
}
})

.then(() => {
  this.afs.collection('Tables').doc(this.selectedTable.toString()).set(
    {
      counter : firebase.firestore.FieldValue.increment(1),
      status : "free"
    },
     {merge: true}
  )
})


.then(() => {
  this.tablecurrentcounter= (Number(this.tablecurrentcounter)+1).toString();

  //console.log(this.selectedTable.toString()+"HHHHH"+this.tablecurrentcounter);

  //newly added
  this.ngOnInit();
  this.qntycounters=this.qntycounters.filter(item=>item.tid!=this.selectedTable.toString());
})
  //newly added
  .then(()=>{
    this.billitemcollection = this.afs.collection('Tables').doc(this.selectedTable.toString()).collection(this.tablecurrentcounter).snapshotChanges()
    .pipe( 
      map(actions => actions.map(a => a.payload.doc.data()))
    );
    //console.log(this.billitemcollection);
    })

    .then(() => {

      this.afs.collection('Bills').doc(this.date2).collection('--stats--').doc('sjr6Ao1emuzGe41CzdJJ').set(
        {
          maxidcounter : firebase.firestore.FieldValue.increment(1),
        },
         {merge: true}
      ).then(() => {
      this.ngOnInit();
      })
      })
      //------newly added------
      this.prcollection='';
      this.totalamount=0.00;
      // this.sumaftrdisc=0.00;
      // this.totamtwithtax=0.00;
      this.summy=[];
      // this.tottaxableamt=0.00;
      // this.cust='';
      // this.custhonor='';
      // this.custname='';
      // this.custaddress11='';
      // this.custaddress12='';
      // this.custaddressS1='';
      // this.custaddressS2='';
      // this.custcontact='';
      // this.custgst='';
        this.favoriteCustomer=this.customers[0]; // set default radio button checked
        this.taxy='gst' // set default radio button checked
        //this.cbilly='no' // set default radio button checked
        //this.modeofpay='CASH'; // set default radio button checked
      this.btnsdisabled();


}
validateId(c) {
  return this.b.find(x => x.id === c);
  }

customqntyeditbtnclkview(c){
  this.custqnty=0;
  this.b=[];
  this.b.push({'id':c});
}


customqntyeditbtnclkclose(c,q){
  this.b=[];
  console.log(q);
  this.customqnty(c,q);
}
}
