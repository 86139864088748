import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AllinoneComponent } from './auth/allinone/allinone.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { SecureInnerPagesGuard } from './auth/guards/secure-inner-pages-guard-guard.guard';
import { BarcodelayoutComponent } from './barcodelayout/barcodelayout.component';
import { BilldeleteComponent } from './billdelete/billdelete.component';
import { BilldetailsComponent } from './billdetails/billdetails.component';
import { BillentryComponent } from './billentry/billentry.component';
import { BillreturnsComponent } from './billreturns/billreturns.component';
import { BookstagsComponent } from './bookstags/bookstags.component';
import { CustomersComponent } from './customers/customers.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DisplaybannersComponent } from './displaybanners/displaybanners.component';
import { EmployeesComponent } from './employees/employees.component';
import { HomeComponent } from './home/home.component';
import { ItemsComponent } from './items/items.component';
import { LabellayoutComponent } from './labellayout/labellayout.component';
import { LedgerbooksComponent } from './ledgerbooks/ledgerbooks.component';
import { LedgerdetailsComponent } from './ledgerdetails/ledgerdetails.component';
import { LedgerfilterComponent } from './ledgerfilter/ledgerfilter.component';
import { MastermenuComponent } from './mastermenu/mastermenu.component';
import { ReportdetailsComponent } from './reportdetails/reportdetails.component';
import { ReportsComponent } from './reports/reports.component';
import { SettingsComponent } from './settings/settings.component';
import { SidebarlComponent } from './sidebarl/sidebarl.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { TopsellingComponent } from './topselling/topselling.component';

const routes: Routes = [
  {
    path: 'allinone',
    component: AllinoneComponent, canActivate: [SecureInnerPagesGuard]
  },
  // {
  //   path: 'login',
  //   component: LoginComponent, canActivate: [SecureInnerPagesGuard]
  // },
  // {
  //   path: 'resetpassword',
  //   component: ResetpasswordComponent, canActivate: [AuthGuard]
  // },
  // {
  //   path: 'register',
  //   component: RegistrationComponent, canActivate: [SecureInnerPagesGuard]
  // },
  {
  path: 'sidebarl',
    component: SidebarlComponent, canActivate: [AuthGuard],
    children:  [
  {
    path: 'home',
    component: HomeComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
  },
  {
    path: 'employees',
    component: EmployeesComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
  },
  {
    path: 'customers',
    component: CustomersComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
  },
  {
    path: 'suppliers',
    component: SuppliersComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
  },
  {
    path: 'ledgerbooks', 
    component: LedgerbooksComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
  },
  {
    path: 'ledgerdetails', 
    component: LedgerdetailsComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
  },
  {
    path: 'ledgerfilter', 
    component: LedgerfilterComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
  },
  {
    path: 'billentry',
    component: BillentryComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
  },
  {
    path: 'billdetails',
    component: BilldetailsComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
  },
  {
    path: 'billreturns',
    component: BillreturnsComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
  },
  {
    path: 'billdelete',
    component: BilldeleteComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
  },
  {
    path: 'customers',
    component: CustomersComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
  },
  {
    path: 'barcodelayout',
    component: BarcodelayoutComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
  },
  {
    path: 'labellayout',
    component: LabellayoutComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    component: ReportsComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
  },
  {
    path: 'reportdetails',
    component: ReportdetailsComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
  },
  {
    path: 'topselling',
    component: TopsellingComponent, outlet:'secondRouter', canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    component: SettingsComponent, outlet:'secondRouter', canActivate: [AuthGuard],
    children: [
      {path: 'mastermenu',component: MastermenuComponent, outlet:'thirdRouter', canActivate: [AuthGuard]},
      // {path: 'brandscategories',component: MastermenuComponent, outlet:'thirdRouter', canActivate: [AuthGuard]},
      {path: 'bookstags',component: BookstagsComponent, outlet:'thirdRouter', canActivate: [AuthGuard]},
      {path: 'displaybanners',component: DisplaybannersComponent, outlet:'thirdRouter', canActivate: [AuthGuard]}
    ]
  },
  {
    path: 'items',
    component: ItemsComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
  },
]
},

  {
    path: '',
    redirectTo: '/sidebarl/(secondRouter:dashboard)',
    pathMatch: 'full'
  }
  ,
  {
    path: '**',
    redirectTo:'/allinone'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
