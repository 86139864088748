import { Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';

declare function allinonemethod(): any;

@Component({
  selector: 'app-allinone',
  templateUrl: './allinone.component.html',
  styleUrls: ['./allinone.component.css']
})
export class AllinoneComponent implements OnInit {
  authError: any;

  constructor(private auth: AuthService) { }

  ngOnInit() {

    allinonemethod();
    this.auth.eventAuthError$.subscribe( data => {
      this.authError = data;
    });
  }

  login(frm) {
    this.auth.login(frm.value.email, frm.value.password);
  }

  createUser(frm) {
    this.auth.createUser(frm.value);
  }

}
