import { Component, OnInit, TrackByFunction } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import * as _ from'lodash';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';

import { DatePipe } from '@angular/common';

import * as moment from 'moment';

import { AppDateAdapter, APP_DATE_FORMATS } from './format-datepicker';
import { MatExpansionPanel } from '@angular/material';
import { animate, state, style, transition, trigger } from '@angular/animations';

interface ImageEnitity {
  index: string; 
  id: string; 
  code: string;
}

const EXPANSION_PANEL_ANIMATION_TIMING = '3000ms cubic-bezier(0.4,0.0,0.2,1)';
MatExpansionPanel['decorators'][0].args[0].animations = [
  trigger('bodyExpansion', [
    state('collapsed, void', style({ height: '0px', visibility: 'hidden' })),
    state('expanded', style({ height: '*', visibility: 'visible' })),
    transition('expanded <=> collapsed, void => collapsed',
      animate(EXPANSION_PANEL_ANIMATION_TIMING)),
  ])];
  
@Component({
  selector: 'app-barcodelayout',
  templateUrl: './barcodelayout.component.html',
  styleUrls: ['./barcodelayout.component.css'],
    providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})
export class BarcodelayoutComponent implements OnInit {
 
  panelOpenState = false;
  public labelForm: FormGroup;

  prdcollection: any;
  myArray: { index: string, id: string, code: string, qntycount: number, rate:number, itemname:string, mfgdate:string, expdate:string, date1:string, date2:string, weight:string }[]= [] //declare before constructor
  labelcode: any;
  lbcollection: any;

  code: any;
  itemname: any='';
  mfgdate: any='';
  expdate: any='';
  weight: any='';
  rate: any='';

enableUpdate: boolean = false;

  constructor(private afs: AngularFirestore, public fb: FormBuilder, public datePipe: DatePipe) { }

  taskTypeAreas: {
    code: any;
    itemname: any;
    rate: any;
    weight: any;
    mfgdate: any;
    expdate: any;
    date1: any;
    date2: any;
  }[] = [
  ];
  selectedOptions: string[] = [];

  ngOnInit() {

    this.taskTypeAreas=[];
  this.lbcollection = this.afs.collection('Expirylabels',ref=>ref.orderBy('code'))
  .get().toPromise()
  .then(querySnapshot => {
      querySnapshot.forEach(doc => {
            this.taskTypeAreas.push({'code':doc.data().code,'itemname':doc.data().itemname,'rate':doc.data().rate,'weight':doc.data().weight,'mfgdate':doc.data().mfgdate,'expdate':doc.data().mfgdate,'date1':doc.data().date1,'date2':doc.data().date2});
          console.log("GGGGG: "+JSON.stringify(this.taskTypeAreas));
      });
  })
  .catch(function(error) {
      console.log("Error getting documents: ", error);
  });

  this.Form();

  }
// Reactive customer form
Form() {
  this.labelForm = this.fb.group({
    itemname: [null, [Validators.required,Validators.minLength(3), Validators.maxLength(30)]],
    rate: [null, [Validators.required]],
    weight: [null, [Validators.required]],
    expdate: [null, [Validators.required]],
    mfgdate: [null, [Validators.required]],
  })
}

    sortagain(){
    this.taskTypeAreas.sort(function(a,b){return a.code.match(/\d+/)[0] - b.code.match(/\d+/)[0]})
  }

  ResetForm() {
    this.labelForm.reset();
    this.enableUpdate=false;
  }
  onSubmit() {
    this.add(this.labelForm.value.itemname);
  
    this.ResetForm();  // Reset form when clicked on reset button
this.ngOnInit();

  }
  add(id) {

    let ids =this.afs.createId();
    this.afs.collection("Expirylabels").doc(ids).set({
      code: ids,
      itemname: this.itemname,
      date1:this.mfgdate,
      mfgdate: this.datePipe.transform(this.mfgdate,"dd-MM-yyyy"),
      date2:this.expdate,
      expdate: this.datePipe.transform(this.expdate,"dd-MM-yyyy"),
      weight: this.weight,
      rate: this.rate,
    })
      .catch((err) => {
        console.log(err);
      })
  }
  delete(event,id) {
    event.stopPropagation();
    this.afs.collection("Expirylabels").doc(id).delete().then(() => {
      console.log('deleted');
    }).then(() => {
      // this.taskTypeAreas = this.taskTypeAreas.filter((item) => item.code !== id);
      // this.myArray = this.myArray.filter((item) => item.code !== id);
      this.ngOnInit();
    })

  }

    // // Accessing form control using getters
    // get itemname() {
    //   return this.labelForm.get('itemname').setValidators([Validators.required, Validators.minLength(3)]);
    // }

    // get rate() {
    //   return this.labelForm.get('rate').setValidators([Validators.required]);
    // }

    // get weight() {
    //   return this.labelForm.get('weight').setValidators([Validators.required]);
    // }

    // get mfgdate() {
    //   return this.labelForm.get('mfgdate').setValidators([Validators.required]);
    // }

    // get expdate() {
    //   return this.labelForm.get('expdate').setValidators([Validators.required]);
    // }

  read(event,tta) {  
    event.stopPropagation();
    this.code= tta.code;
    this.itemname = tta.itemname;
    this.rate = tta.rate;
    this.weight=tta.weight;
    this.mfgdate= ((moment(tta.mfgdate, "DD-MM-YYYY").toDate()));
    this.expdate = ((moment(tta.expdate, "DD-MM-YYYY").toDate()));
  }

  update(event,id) {
    event.stopPropagation();
    this.afs.collection("Expirylabels").doc(id).update({
      itemname:this.itemname,
      rate: this.rate,
      weight: this.weight,
      date1: (this.mfgdate!="Invalid Date") ? this.mfgdate : null,
      mfgdate: (this.mfgdate!="Invalid Date") ? (this.datePipe.transform(this.mfgdate,"dd-MM-yyyy")) : null,
      date2: (this.expdate!="Invalid Date") ? this.expdate : null,
      expdate: (this.expdate!="Invalid Date") ? (this.datePipe.transform(this.expdate,"dd-MM-yyyy")) : null,
    }).then(() => {
      console.log('updatedF');
      this.ResetForm();
    })
  }


  /**
   * @default 2
   */
  public columns = 2;

  public get table(): ImageEnitity[][] {
    const rowCount = Math.floor(this.myArray.length / this.columns);
    const remainder = this.myArray.length % this.columns;
    const rows = [];
    for (let i = 0; i < rowCount; i++) {
      rows.push(this.myArray.slice(i * this.columns, (i * this.columns) + this.columns))
    }
    if (remainder) {
      rows.push(this.myArray.slice(this.myArray.length - remainder, this.myArray.length));
    }
    return rows;
  };

  /**
   * https://netbasal.com/angular-2-improve-performance-with-trackby-cc147b5104e5
   */
  public trackRow: TrackByFunction<ImageEnitity[]> = (index, item) => {
    return index;
  };

  /**
   * https://netbasal.com/angular-2-improve-performance-with-trackby-cc147b5104e5
   */
  public trackImage: TrackByFunction<ImageEnitity> = (index, item) => {
    //return item.name;
    return item.id;
  };

  
updateCheckedOptions(tta,i){

  tta.checked = !tta.checked;
  console.log(this.selectedOptions.length);
 // for (var i = 0; i < (this.selectedOptions.length); i++) {
    console.log(tta.code);
    this.afs.collection('Expirylabels', ref => ref.where('code', "==" , tta.code)).get().toPromise().then(querySnapshot => {
    if (!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        if(tta.checked==true){
          this.myArray.push({'index':i,'id':doc.data().code,'code':doc.data().code, 'qntycount':1, 'rate':doc.data().rate, 'itemname':doc.data().itemname, 'mfgdate':doc.data().mfgdate, 'expdate':doc.data().expdate,'date1':doc.data().date1, 'date2':doc.data().date2, 'weight':doc.data().weight});
          console.log("T: "+JSON.stringify(this.myArray));

        //   for(var m = 0; m < this.myArray.length ; m++){
        //     this.myArray.push(this.myArray[m]);
        // }
        
        }
        else {
          console.log("GGG: "+JSON.stringify(this.myArray));
          const srch =  this.myArray.map(function(e) { return e.code; }).indexOf(doc.data().code);
          console.log("SSSS: "+JSON.stringify(this.myArray));
          if (srch > -1) {
            const cnt=this.myArray.filter(i=>i.code=== doc.data().code).length;
                      console.log("CCCC: "+cnt);

            this.myArray.splice(srch,cnt);
          }
          console.log("GGGGG: "+JSON.stringify(this.myArray));
        }
      }) ;
      return false;         
    }

  });

  console.log("R: "+JSON.stringify(this.myArray));
  return this.myArray;

}
insert(c,i){

  let countUpdated = {'bcid': c, 'qntycount':0};

  this.afs.collection('Expirylabels', ref => ref.where('code', '==' , c)).get().toPromise().then(querySnapshot => {
    //if (querySnapshot.size > 0) {

      querySnapshot.forEach(doc => {
        if (doc.data().code == c) {
 
        //qntycounters update
        this.myArray.find(item => item.code == countUpdated.bcid).qntycount = this.myArray.find(item => item.code == countUpdated.bcid).qntycount+ 1;
        console.log(JSON.stringify(this.myArray));
        //qntycounters update
        
      }
  })
  })


const srch =  this.myArray.map(function(e) { return e.code; }).indexOf(c);
console.log(srch);
if (srch > -1) {
  this.myArray.splice(srch,0,this.myArray.find(item => item.code === c));
}
console.log("IIIIII: "+JSON.stringify(this.myArray));
}

remove(c,i){

  let countUpdated = {'bcid': c, 'qntycount':0};

  this.afs.collection('Expirylabels', ref => ref.where('code', '==' , c)).get().toPromise().then(querySnapshot => {
    //if (querySnapshot.size > 0) {

      querySnapshot.forEach(doc => {
        if (doc.data().code == c) {
 
        //qntycounters update
        this.myArray.find(item => item.code == countUpdated.bcid).qntycount = this.myArray.find(item => item.code == countUpdated.bcid).qntycount- 1;
        console.log(JSON.stringify(this.myArray));
        //qntycounters update
        
      }
  })
  })

  

const srch =  this.myArray.map(function(e) { return e.code; }).indexOf(c);
console.log(srch);
if (srch > -1) {
  this.myArray.splice(srch,1);
}
console.log("RRRRRR: "+JSON.stringify(this.myArray));
}

}