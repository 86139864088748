import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class Drawer3Service {

  private messageSource3 = new BehaviorSubject(true); //false is 'default message'
  currentMessage3 = this.messageSource3.asObservable();

  constructor() { }

  changeMessage3(message3: boolean) {
    this.messageSource3.next(message3)
  }

}