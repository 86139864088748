import { BrowserModule } from '@angular/platform-browser';
import { NgModule, enableProdMode } from '@angular/core';

import {DatePipe} from '@angular/common';

import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularFontAwesomeModule } from 'angular-font-awesome';

import { FlexLayoutModule } from '@angular/flex-layout';

import { QRCodeModule } from 'angularx-qrcode';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { HttpClientModule } from '@angular/common/http';
import { MatExpansionModule, MatTooltipModule, MatSnackBarModule, MatCardModule, MatIconModule, MatTableModule, MatSortModule, MatPaginatorModule, MatInputModule, MatAutocompleteModule, MatButtonModule, MatRadioModule, MatDialogModule, MatDatepickerModule, MatNativeDateModule, MatSelectModule, MatCheckboxModule, MatListModule, MatProgressBarModule, MatGridListModule, MatSidenavModule, MatToolbarModule, MatBottomSheetModule, MatButtonToggleModule} from '@angular/material';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { MatTableExporterModule } from 'mat-table-exporter';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { NgxPaginationModule } from 'ngx-pagination';
import {NgxPrintModule} from 'ngx-print';
import { HomeComponent } from './home/home.component';

import {NgPipesModule} from 'ngx-pipes';
import { AvatarModule } from 'ngx-avatar';
import { SidebarlComponent } from './sidebarl/sidebarl.component';
import { Drawer2Service } from 'src/assets/services/drawer2.service';
import { Drawer3Service } from 'src/assets/services/drawer3.service';
import { Drawer10Service } from 'src/assets/services/drawer10.service';
import { Drawer11Service } from 'src/assets/services/drawer11.service';
import { CustomerprofileService } from 'src/assets/services/customerprofile.service';
import { LedgerdetailsService } from 'src/assets/services/ledgerdetails.service';
import { AllinoneComponent } from './auth/allinone/allinone.component';
import { LoginComponent } from './auth/login/login.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { ResetpasswordComponent } from './auth/resetpassword/resetpassword.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BillentryComponent } from './billentry/billentry.component';
import { MastermenuComponent } from './mastermenu/mastermenu.component';
import { SettingsComponent } from './settings/settings.component';
import { ItemsComponent } from './items/items.component';
import { BarcodelayoutComponent } from './barcodelayout/barcodelayout.component';
import { filterproduct } from 'src/assets/shared/sharedpipes/filterproduct.pipe';
import { filteruniquecode } from 'src/assets/shared/sharedpipes/filteruniquecode.pipe';
import { filteruniquecolor } from 'src/assets/shared/sharedpipes/filteruniquecolor.pipe';
import { filteruniquesize } from 'src/assets/shared/sharedpipes/filteruniquesize.pipe';
import { filteruniquecategory } from 'src/assets/shared/sharedpipes/filteruniquecategory.pipe';
import { filteruniquebrand } from 'src/assets/shared/sharedpipes/filteruniquebrand.pipe';
import { GroupByPipe } from 'src/assets/shared/sharedpipes/group-by.pipe';
import { NumberToWordsPipe } from 'src/assets/shared/sharedpipes/number-to-words.pipe';
import { BillreturnsComponent } from './billreturns/billreturns.component';
import { BilldetailsComponent } from './billdetails/billdetails.component';
import { BilldeleteComponent, BottomSheetBillDelete } from './billdelete/billdelete.component';
import { CustomersComponent } from './customers/customers.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportdetailsComponent } from './reportdetails/reportdetails.component';
import { LabellayoutComponent } from './labellayout/labellayout.component';
import { LedgerbooksComponent } from './ledgerbooks/ledgerbooks.component';
import { LedgerfilterComponent } from './ledgerfilter/ledgerfilter.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { EmployeesComponent } from './employees/employees.component';
import { LedgerdetailsComponent } from './ledgerdetails/ledgerdetails.component';
import { BookstagsComponent } from './bookstags/bookstags.component';
import { DisplaybannersComponent } from './displaybanners/displaybanners.component';
import { TopsellingComponent } from './topselling/topselling.component';
import { Drawer12Service } from 'src/assets/services/drawer12.service';

var config = {
  apiKey: "AIzaSyBLs0MIhIb-4K1MigY57wYCKdKnu_saTlo",
  authDomain: "bakeryposdl.firebaseapp.com",
  databaseURL: "https://bakeryposdl.firebaseio.com",
  projectId: "bakeryposdl",
  storageBucket: "bakeryposdl.appspot.com",
  messagingSenderId: "139560732219",
  appId: "1:139560732219:web:009eb469200357f6c9be1e",
  measurementId: "G-RHMWGY2T01"
};
enableProdMode();

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    QRCodeModule,
    AngularFontAwesomeModule,
    MatSidenavModule,
    MatToolbarModule,
    MatExpansionModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDialogModule, 
    MatBottomSheetModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatCheckboxModule,
    MatListModule,
    MatProgressBarModule,
    MatGridListModule,
    MatCarouselModule.forRoot(),
    MatTableExporterModule,
    MatProgressSpinnerModule,
    NgxPaginationModule,
    NgxPrintModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(config),
    AngularFirestoreModule, // firestore
    AngularFireDatabaseModule, //database
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage
    NgPipesModule,
    AvatarModule,
    ],
  providers: [MatDatepickerModule,DatePipe,Drawer2Service,Drawer3Service,Drawer10Service,Drawer11Service,Drawer12Service,CustomerprofileService,LedgerdetailsService],
  entryComponents: [BilldeleteComponent, BottomSheetBillDelete],
  declarations: [ NumberToWordsPipe, GroupByPipe, filteruniquebrand, filteruniquecategory, filteruniquesize, filteruniquecolor, filteruniquecode, filterproduct, AppComponent, HomeComponent, SidebarlComponent, AllinoneComponent, LoginComponent, RegistrationComponent, ResetpasswordComponent, DashboardComponent, BillentryComponent, MastermenuComponent, SettingsComponent, ItemsComponent, BarcodelayoutComponent, BillreturnsComponent, BilldetailsComponent, BilldeleteComponent, BottomSheetBillDelete, CustomersComponent, ReportsComponent, ReportdetailsComponent, LabellayoutComponent, EmployeesComponent, SuppliersComponent, LedgerbooksComponent,LedgerfilterComponent,LedgerdetailsComponent, BookstagsComponent, DisplaybannersComponent, TopsellingComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }

