import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class Drawer12Service {

  private messageSource12 = new BehaviorSubject(true); //false is 'default message'
  currentMessage12 = this.messageSource12.asObservable();

  constructor() { }

  changeMessage12(message12: boolean) {
    this.messageSource12.next(message12)
  }

}