import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

import 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';

import * as CanvasJS from './canvasjs.min';
import { Router, ActivatedRoute } from '@angular/router';


declare function dashboardSniper(): any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit ,AfterViewInit{
  imgcollection: any[]=[];

  blcollection: any;
  sblcollection: any;
  salecollection: any;
  totalbills: any =0;
  shop1bills: any =0;
  shop2bills: any =0;
  godownbills: any =0;

  partytypes: any =0;
  seasonaltypes: any =0;
  festivaltypes: any =0;
  regulartypes: any =0;

  gsttypes: any =0;
  igsttypes: any =0;
  taxfreetypes: any =0;


  tottaxableamtpre: number;
  totamtwithtaxpre: number;
  sumaftrdiscpre: number;

  tottaxableamt: { billid:any; tottaxableamtpre: number }[]= [];
  totamtwithtax: { billid:any; totamtwithtaxpre: number }[]= [];
  sumaftrdisc: { billid:any; sumaftrdiscpre: number }[]= [];

  totalamountpre: number;
  totalamount: { billid:any; totalamountpre: number }[]= [];

  
  summy: { billid:any, shop:string, gst: number, rate: number, taxtype:string, taxableamt: number, amtaftdisc:number }[]= [];
  summypre: { billid:any, shop:string, gst: number, rate: number, taxtype:string, taxableamt: number, amtaftdisc:number }[]= [];
  totalrate: number;

  pcollection: any;



  eventdate: string;

  shopbillsarry: { shop1bills: number; shop2bills: number; godownbills: number }[]= [];
  customertypesarry: { partytypes: number; seasonaltypes: number; festivaltypes: number; regulartypes: number }[]= [];
  taxtypesarry: { gsttypes: number; igsttypes: number; taxfreetypes: number }[]= [];

  osmamntwitxs1: number;
  osmamntwitxs2: number;
  osmamntwitxgn: number;
  otxamnts1gst: number;
  otxamnts2gst: number;
  otxamnts1igst: number;
  otxamntgngst: number;
  otxamntgnigst: number;
  otxamnts2igst: number;
  otxamnts1taxfree: number;
  otxamnts2taxfree: number;
  otxamntgntaxfree: number;
  overallsumtaxableamt: number;
  overallsumamtwithtax: number;

  constructor(private afs: AngularFirestore, private route: ActivatedRoute, public router: Router) {   }

  addorreplace(array, item){
    const i=array.findIndex(_item => _item.billid===item.billid);
    if(i>-1) array[i] =item;
    else array.push(item);
   }

   addorreplace2(array, item){
  }

   date="";
   day="";
   month="";
   year="";

   clock="";
  clockHandle;
  ngOnInit() {

    this.clockHandle = setInterval(()=>{
      this.clock = moment().format("dddd, MMMM Do YYYY, h:mm:ss a").toLocaleString();
    },1000);

    this.date=moment().date().toString();
    this.day=moment().format('dddd');
    this.month=moment().format('MMMM');
    this.year=moment().format('YYYY');

    //this.imgcollection.push({images:["assets/images/slideimage.png"]});

    this.afs.collection('Settings', ref => ref.where('settingid', "==" , 'dbanner'))
.get().toPromise()
.then(querySnapshot => {
    querySnapshot.forEach(doc => {
     // this.imgcollection.push(doc.data());
        // this.imgcollection.push({images:["assets/images/slideimage.png",doc.data().images]});
        this.imgcollection.push({images:["assets/images/slideimage.png"]});

    });
})
console.log(this.imgcollection);
    this.shopbillsarry=[];

    this.eventdate=moment().format('DD-MM-YYYY')
    this.blcollection =[];
    this.blcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" ,this.eventdate))
    .snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    )

  }

  
  // ngForRendred() {
  //   dashboardSniper();
  // }

  ngAfterViewInit() {
    dashboardSniper();


    // var messagebird1 = messagebird('oVolyJ7mCvVYF8nWH9DTDgRk7');

    // var params = {
    //   'to': '+919123552087',
    //   'from': '626aba24-5275-4d1f-b355-018df9369275',
    //   'type': 'text',
    //   'content': {
    //     'text': 'Hello!'
    //   },
    //   reportUrl: null
    // };
    
    // messagebird1.conversations.send(params, function (err, response) {
    //   if (err) {
    //     return console.log(err);
    //   }
    //   console.log(response);
    // });



    //-------newly added-------
    this.blcollection.subscribe(() => {

          this.shop1bills =0;
      this.shop2bills =0;
      this.godownbills =0;

    this.sblcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" ,this.eventdate)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(doc => {
      for(let i=0;i<doc.data().billsid.length;i++){
 
      if (String(doc.data().billsid[i]).slice(0, 2) == "S1") {
        this.shop1bills = this.shop1bills+1;
        this.addorreplace(this.shopbillsarry,{'shop1bills':this.shop1bills , 'shop2bills':this.shop2bills, 'godownbills':this.godownbills})

      }
      else if (String(doc.data().billsid[i]).slice(0, 2) == "S2") {
        this.shop2bills = this.shop2bills+1;
        this.addorreplace(this.shopbillsarry,{'shop1bills':this.shop1bills , 'shop2bills':this.shop2bills, 'godownbills':this.godownbills})
      }
      else if (String(doc.data().billsid[i]).slice(0, 2) == "GN") {
        this.godownbills = this.godownbills+1;
        this.addorreplace(this.shopbillsarry,{'shop1bills':this.shop1bills , 'shop2bills':this.shop2bills, 'godownbills':this.godownbills})
      }
    }
  })

    })
    //-------newly added-------

  this.tottaxableamtpre=0;
  this.totamtwithtaxpre=0; 
  this.sumaftrdiscpre=0; 

  this.totalamountpre=0;

  this.tottaxableamt=[];
  this.totamtwithtax=[]; 
  this.sumaftrdisc=[]; 
this.totalamount=[];
  
  this.summy=[];


      this.afs.collection('Bills', ref => ref.where('date2', "==" ,this.eventdate)).get().toPromise().then(querySnapshot => {
        querySnapshot.forEach(doc => {

          for(let i=1;i<=doc.data().billsid.length;i++){
            
            
            this.tottaxableamtpre=0;
            this.totamtwithtaxpre=0; 
            this.sumaftrdiscpre=0; 

            this.totalamountpre=0;
        
         this.afs.collection('Bills').doc(this.eventdate).collection('--Id--',ref => ref.where('currentbillid', '==', Number(doc.data().billsid[i-1].substring(4)))).get().toPromise().then(querySnapshot => {
            querySnapshot.forEach(docy => {
                          this.pcollection = this.afs.collection('Bills').doc(this.eventdate).collection(String(docy.data().currentbillid)).snapshotChanges()
                          .pipe( 
                            map(actions => actions.map(a => a.payload.doc.data()))
           );
        
          this.pcollection.subscribe((posts) => {

            console.log("posty:"+posts); //This is the array of posts, not an observable
        
            if(doc.data().billsid[i-1].substring(3,4)=='G'){
            this.summypre=(_(posts).groupBy('cgst')
              .map((objs, key) => {
                  return {
                'billid':doc.data().billsid[i-1].substring(4),
                'shop':String(doc.data().billsid[i-1]).slice(0, 2),
                'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
                'taxtype':'gst',
                'taxableamt':2 * ((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
                  }
              })
              .value()
            );
            }
            if(doc.data().billsid[i-1].substring(3,4)=='I'){
              this.summypre=(_(posts).groupBy('igst')
                .map((objs, key) => {
                    return {
                  'billid':doc.data().billsid[i-1].substring(4),
                  'shop':String(doc.data().billsid[i-1]).slice(0, 2),
                  'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
                  'taxtype':'igst',
                  'taxableamt':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
                }
                })
                .value()
              );
              }
              if(doc.data().billsid[i-1].substring(3,4)=='T'){
                this.summypre=(_(posts).groupBy('taxfree')
                  .map((objs, key) => {
                      return {
                    'billid':doc.data().billsid[i-1].substring(4),
                    'shop':String(doc.data().billsid[i-1]).slice(0, 2),
                    'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
                    'taxtype':'taxfree',
                    'taxableamt':(parseFloat('0.00')),
                  }
                  })
                  .value()
                );
                }
            this.summy=this.summy.concat(this.summypre);
        
            this.tottaxableamtpre=this.summypre.reduce((sum1, item1) => sum1 + item1.taxableamt, 0)
            this.totamtwithtaxpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0) + this.tottaxableamtpre;
            this.sumaftrdiscpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0);

            this.addorreplace(this.tottaxableamt,{'billid':doc.data().billsid[i-1].substring(4), tottaxableamtpre:this.tottaxableamtpre})
            this.addorreplace(this.totamtwithtax,{'billid':doc.data().billsid[i-1].substring(4), totamtwithtaxpre:this.totamtwithtaxpre})
            this.addorreplace(this.sumaftrdisc,{'billid':doc.data().billsid[i-1].substring(4), sumaftrdiscpre:this.sumaftrdiscpre})

            console.log("TAXXXX:"+JSON.stringify(this.totamtwithtax));
        
              console.log("SUMMYYY:"+JSON.stringify(this.summy));
        

              

        const result = this.summy.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0);
        const result2 = this.summy.reduce((sum2, item2) => sum2 + item2.taxableamt, 0);
        //----------chart-------------

        CanvasJS.addColorSet("greenShades",
        [//colorSet Array

        "#1e331e",
        "#89e689",               
        ]);

       let chart = new CanvasJS.Chart("chartContainer", {
        //theme: "light2",
        colorSet: "greenShades",
        animationEnabled: true,
        bevelEnabled: true,
        // ex7ortEnabled: true,
        // title:{
        //   text: "Today Sales",
        //   fontFamily: "tahoma",
        //   fontSize:20
        // },
        toolTip: {
			cornerRadius: 50
		},
        data: [{
          type: "doughnut",
		startAngle: 60,
		//innerRadius: 60,
    radius: "100%",
		//indexLabelFontSize: 17,
          //type: "pie",
          showInLegend: true,
          toolTipContent: "<b>{name}</b>: ₹{y} (#percent%)",
          //indexLabel: "{name} '\n' #percent%",
          dataPoints: [
            { y: result, name: "Net" },
            { y: result2, name: "Tax" },
          ]
        }]
      });        
      chart.render();
      
       //----------chart-------------

       
      console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+result);
      
      this.overallsumtaxableamt = this.summy.reduce((sum1, item1) => sum1 + item1.taxableamt, 0)
      this.overallsumamtwithtax = this.summy.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0) + this.overallsumtaxableamt;


                  this.totalbills = doc.data().billsid.length;

      this.otxamnts1gst=this.summy.filter(item => item.shop === "S1" && item.taxtype === "gst").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamnts2gst=this.summy.filter(item => item.shop === "S2" && item.taxtype === "gst").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamntgngst=this.summy.filter(item => item.shop === "GN" && item.taxtype === "gst").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamnts1igst=this.summy.filter(item => item.shop === "S1" && item.taxtype === "igst").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamnts2igst=this.summy.filter(item => item.shop === "S2" && item.taxtype === "igst").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamntgnigst=this.summy.filter(item => item.shop === "GN" && item.taxtype === "igst").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamnts1taxfree=this.summy.filter(item => item.shop === "S1" && item.taxtype === "taxfree").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamnts2taxfree=this.summy.filter(item => item.shop === "S2" && item.taxtype === "taxfree").reduce((a, b) => a + b.taxableamt, 0);
       this.otxamntgntaxfree=this.summy.filter(item => item.shop === "GN" && item.taxtype === "taxfree").reduce((a, b) => a + b.taxableamt, 0);

      this.osmamntwitxs1=this.summy.filter(item => item.shop === "S1").reduce((a, b) => a + b.amtaftdisc, 0);
       this.osmamntwitxs2=this.summy.filter(item => item.shop === "S2").reduce((a, b) => a + b.amtaftdisc, 0);
       this.osmamntwitxgn=this.summy.filter(item => item.shop === "GN").reduce((a, b) => a + b.amtaftdisc, 0);

    })

  })
})

}

    })
  })
})//-------newly added-------
  }


  viewreport() {  
      this.router.navigate(['../billentry'], {relativeTo: this.route})
        }


}
