import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { AnimationDurations } from '@angular/material';
import { firestore } from 'firebase';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-bookstags',
  templateUrl: './bookstags.component.html',
  styleUrls: ['./bookstags.component.css']
})
export class BookstagsComponent implements OnInit {

  public BForm: FormGroup;
  public TForm: FormGroup;

  booknew: any;
  tagnew: any;
  bkcollection: any;
  tgcollection: any;
  selectedbook: any;
  selectedtag: any;
  selectedbook2: any;
  selectedtag2: any;
  bkcollection2: any;
  tgcollection2: any;

  constructor(private afs: AngularFirestore, public fb: FormBuilder) { }

  ngOnInit() {
    this.FormB();
    this.FormT();

    this.bkcollection =[];
    this.bkcollection = this.afs.collection('LedgerBooks').snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
    
    this.tgcollection =[];
    this.tgcollection = this.afs.collection('Tags',ref => ref.orderBy('alltags','asc')).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );


    this.bkcollection2 =[];
    this.bkcollection2 = this.afs.collection('LedgerBooks').snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

  }

   // Reactive particular form
   FormB() {
    this.BForm = this.fb.group({
      newbook: [''],
    })
  }
  // Accessing form control using getters
  get newbook() {
    return this.BForm.get('newbook');
  }

// Reactive particular form
FormT() {
  this.TForm = this.fb.group({
    newtag: [''],
  })
}
// Accessing form control using getters
get newtag() {
  return this.TForm.get('newtag');
}

bsubmitbtn(){
  this.afs.collection('LedgerBooks').doc(String(this.booknew).toUpperCase()).set(
    {
      bookname : String(this.booknew).toUpperCase()
    },
     {merge: true}
)
}

bdeletebtn(bookname){
  this.afs.collection('LedgerBooks').doc(bookname).delete().then(function() {
    console.log("Document successfully deleted!");
}).catch(function(error) {
    console.error("Error removing document: ", error);
});
}

tsubmitbtn(){
  this.afs.collection('Tags').doc('alltags').set(
    {
      alltags:firestore.FieldValue.arrayUnion(String(this.tagnew).toUpperCase()),
      // committed:'', 
    },
     {merge: true}
)
}

tdeletebtn(tgname){
  this.afs.collection('Tags').doc('alltags').update({
    alltags:firestore.FieldValue.arrayRemove(tgname)
  })
}

changeBook(bookselected){
this.selectedbook=bookselected;
}
changeTag(tagselected){
this.selectedtag=tagselected;
}

btcommitbtn(bk,tg){

  
  this.afs.collection('BookTagCounter').get().toPromise().then( querySnapshot => {
    querySnapshot.forEach( doc => {

      let a=doc.data().count;
      console.log(a)

this.afs.collection('LedgerBooks').doc(bk).get().toPromise().then(doc => {
if(!doc.data().tags){
  this.afs.collection('LedgerBooks').doc(bk).update({
    tags:firestore.FieldValue.arrayUnion({tagname:String(tg).toUpperCase(),id:a}) 
  }).then(()=>{

  this.afs.collection('Tags').doc('alltags').update({
    committed:firestore.FieldValue.arrayUnion(String(tg).toUpperCase()) 
  }).then(()=>{
  
    this.afs.collection('BookTagCounter').doc('Counter').update({
      count: firestore.FieldValue.increment(1)
    })
   })
  })

}
var arr=[];    
if(doc.data().tags){
  for(let i=0;i<doc.data().tags.length;i++){
    if(doc.data().tags[i].tagname == tg){
      arr.push(tg)
      console.log("ALREADY COMMITTED")
    }
    if(doc.data().tags[i].tagname != tg){
    }
}

if(arr.length==0){
this.afs.collection('LedgerBooks').doc(bk).update({
  tags:firestore.FieldValue.arrayUnion({tagname:String(tg).toUpperCase(),id:a}) 
}).then(()=>{

this.afs.collection('Tags').doc('alltags').update({
  committed:firestore.FieldValue.arrayUnion(String(tg).toUpperCase()) 
}).then(()=>{

  this.afs.collection('BookTagCounter').doc('Counter').update({
    count: firestore.FieldValue.increment(1)
  })
 })
})
console.log("NEWLY COMMITTED")

    }
  }

})

})

  })

}


changeBook2(bookselected){
  this.selectedbook2=bookselected;
    
  this.tgcollection2 =[];
  this.tgcollection2 = this.afs.collection('LedgerBooks',ref => ref.where('bookname', "==" , bookselected)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );

  }

  changeTag2(bookselected,tagselected){
  this.selectedtag2=tagselected;

  }



  
}