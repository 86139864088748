import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-displaybanners',
  templateUrl: './displaybanners.component.html',
  styleUrls: ['./displaybanners.component.css']
})
export class DisplaybannersComponent implements OnInit {
  public pcForm: FormGroup;
  filetot: number;

  constructor(private _sanitizer: DomSanitizer, private afs: AngularFirestore, private fb: FormBuilder){ }

  ngOnInit() {
    this.Form();
    this.read();  
  }

  // Reactive form
  Form() {
    this.pcForm = this.fb.group({
      bannerimgs: [''],
    })
  }

  get bannerimgs() {
    return this.pcForm.get('bannerimgs');
  }


  // Reset form's values
// ResetForm() {
//   this.urls = [];
//   this.filetot= 0;
//   this.pcForm.reset();
// }


submitProductbuildData() {
  this.addupdate(this.pcForm.value.id);
  
  //this.ResetForm();  // Reset form when clicked on reset button
};

addupdate(id) {
  this.afs.collection("Settings").doc("displaybanners").set({
    settingid:'dbanner',
     images:this.urls, //images  
 }, { merge: true })

   .catch((err) => {
     console.log(err);
   })   
}


read() {  
  this.afs.collection("Settings", ref => ref.where('settingid', "==" , 'dbanner'))
.get().toPromise()
.then(querySnapshot => {
    querySnapshot.forEach(doc => {
      for(var l=0;l<doc.data().images.length;l++){
      this.urls.push(doc.data().images[l]);
      }
    });
     this.filetot = this.urls.length;
})
.catch(function(error) {
    console.log("Error getting documents: ", error);
})

}


urls = new Array<string>();
detectFiles(event) {
  //this.urls = [];
  let files = event.target.files;
  if (files) {
    for (let file of files) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.urls.push(e.target.result);
        console.log(this.urls);
        this.filetot = this.urls.length;

      }
      reader.readAsDataURL(file);
    }
  }
}

deleteImage(key){
  this.urls.splice(key,1);
  this.filetot = this.urls.length;
  console.log(this.urls);
}

}

