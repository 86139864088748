import { Component, OnInit } from '@angular/core';
import { NgModel, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

import 'rxjs';
export interface Item { name: string; rate: number;}
import * as _ from 'lodash';
import * as moment from 'moment';
import * as firebase from 'firebase';
import "firebase/firestore";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  date2= moment().format('DD-MM-YYYY');
  showMainContent: Boolean = true;
  menucollection: any = [];
  itemcollection: any = [];
  billitemcollection: any = [];
  selectedMenu: any= null;
  selectedItem: any;
  selectedTable: any= "Table 1";
  tables: any;

  //qntycounters
qntycounters: { tid: string, itemid: string, qntycount: number }[]= [] //declare before constructor
//qntycounters
tablecurrentcounter: any ="2";


addorreplace(array, item){
  const i=array.findIndex(_item => _item.tid===item.tid && _item.itemid===item.itemid);
  if(i>-1) array[i] =item;
  else array.push(item);
 }

  constructor(private afs: AngularFirestore) {
 
    }
  

    async ngOnInit() {
    this.loadtable();
this.loadmenu();
this.billitemcollection='';
  }
loadtable(){
  this.tables = [
    { id: 91, name: "TAKE AWAY 1" },
    { id: 92, name: "TAKE AWAY 2" },
    { id: 1, name: "TABLE 1" },
    { id: 2, name: "TABLE 2" },
    { id: 3, name: "TABLE 3" },
    { id: 4, name: "TABLE 4" },
    { id: 5, name: "TABLE 5" },
    { id: 6, name: "TABLE 6" },
    { id: 7, name: "TABLE 7" },
    { id: 8, name: "TABLE 8" },
    { id: 9, name: "TABLE 9" },
    { id: 10, name: "TABLE 10" },
    { id: 100, name: "ORDER" },
  ];
}
  loadmenu(){
    this.menucollection =   this.afs.collection('Mastermenu').snapshotChanges()      
    .pipe( 
      map(actions => actions.map(a => a.payload.doc.data()))
    );
  }
loaditem(){
  this.itemcollection =   this.afs.collection('Items', ref => ref.where('mastermenu', '==', this.selectedMenu)).snapshotChanges()      
  .pipe( 
    map(actions => actions.map(a => a.payload.doc.data()))
  );
}

loadbill(tbname: any){
    console.log(tbname);
    this.afs.collection('Tables').doc(tbname).get().toPromise().then(  doc => {
      this.tablecurrentcounter=doc.data().counter.toString();
  }).then(()=>{
this.billitemcollection = this.afs.collection('Tables').doc(tbname).collection(this.tablecurrentcounter).snapshotChanges()
  .pipe( 
    map(actions => actions.map(a => a.payload.doc.data()))
  );
  console.log(this.billitemcollection);
})
  }
upcount(itmid: any,itname: any, qnty: any){
  this.afs.collection('Tables').doc(this.selectedTable).collection(this.tablecurrentcounter.toString()).get().toPromise().then(querySnapshot => {

      querySnapshot.forEach(doc => {
        if (doc.data().itemid == itmid) {
          if(!this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid)){
            //qntycounters insert
            this.addorreplace(this.qntycounters,{'tid': this.selectedTable,'itemid':itmid, qntycount: qnty + doc.data().unit1});
            console.log(JSON.stringify(this.qntycounters));
            //qntycounters insert
              }
            else {
            //qntycounters update
            this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount = this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount + doc.data().unit1;
            console.log(JSON.stringify(this.qntycounters));
            //qntycounters update
            }

  this.afs.collection('Tables').doc(this.selectedTable).collection(this.tablecurrentcounter.toString()).doc(itname).set(
    {
      qnty : this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount, //qnty
    },
     {merge: true}
).then(()=>{
  
})
  .catch((err) => {
    console.log(err);
  })
        }
      })
      
    }).then(()=>{
  
    })
      .catch((err) => {
        console.log(err);
      })
  
}

downcount(itmid: any,itname: any, qnty: any){
  this.afs.collection('Tables').doc(this.selectedTable).collection(this.tablecurrentcounter.toString()).get().toPromise().then(querySnapshot => {

      querySnapshot.forEach(doc => {
        if (doc.data().itemid == itmid) {
          if(!this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid)){
            //qntycounters insert
            this.addorreplace(this.qntycounters,{'tid': this.selectedTable,'itemid':itmid, qntycount: qnty - doc.data().unit1});
            console.log(JSON.stringify(this.qntycounters));
            //qntycounters insert
              }
            else {
            //qntycounters update
            this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount = this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount - doc.data().unit1;
            console.log(JSON.stringify(this.qntycounters));
            //qntycounters update
            }

  this.afs.collection('Tables').doc(this.selectedTable).collection(this.tablecurrentcounter.toString()).doc(itname).set(
    {
      qnty : this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount, //qnty
    },
     {merge: true}
).then(()=>{
  
})
  .catch((err) => {
    console.log(err);
  })
        }
      })
      
    }).then(()=>{
  
    })
      .catch((err) => {
        console.log(err);
      })
  
}
removebillitem(itemid,itname){
  this.afs.collection('Tables').doc(this.selectedTable).collection(this.tablecurrentcounter.toString()).doc(itname).delete()

}

 ShowHideButton() {
  this.showMainContent = this.showMainContent ? false : true;     
}
menuclicked(mn: any) {
  this.selectedMenu = mn;
  this.loaditem();
  this.ShowHideButton();
}
    itemclicked(itname: any, itmid:any) { 

      this.selectedItem = itname;    
      console.log(itname,itmid);

      this.loadbillbasiccontent(itname,itmid);
      //this.ShowHideButton();
   }
   selectedtablechange(tb: any){
     this.selectedTable=tb;
     console.log(this.selectedTable);

     this.loadbill(tb);
   }
   
   loadbillbasiccontent(itname,itmid){

    console.log("tablecurrentcounter>>>"+ this.tablecurrentcounter);

    this.afs.collection('Tables').doc(this.selectedTable).collection(this.tablecurrentcounter.toString()).get().toPromise().then(sub=>{
if(sub.docs.length>0)
{
  console.log("exists");

  sub.forEach(docy => {
    this.addorreplace(this.qntycounters,{'tid': this.selectedTable,'itemid':docy.data().itemid, qntycount: docy.data().qnty});
    console.log(JSON.stringify(this.qntycounters));
  })
  
  

  console.log("tablecurrentcounter:"+ this.tablecurrentcounter.toString());
    this.afs.collection('Tables', ref => ref.where('tableid', '==', this.selectedTable)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(docy => {
        this.afs.collection('Items', ref => ref.where('itemid', '==', itmid)).get().toPromise().then(querySnapshot => {
          querySnapshot.forEach(doc => {

            if(!this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid)){

            this.afs.collection('Tables').doc(this.selectedTable).collection(this.tablecurrentcounter.toString()).doc(itname).set(
              {
                itemid: doc.data().itemid,
                itemname: doc.data().itemname,
                qnty: doc.data().unit1,
                unit1: doc.data().unit1,
              },
              { merge: true }
            )
            

          //qntycounters insert
          this.addorreplace(this.qntycounters,{'tid': this.selectedTable,'itemid':itmid, qntycount: doc.data().unit1});
          console.log(JSON.stringify(this.qntycounters));
          //qntycounters insert
            }

            
            else{

              this.afs.collection('Tables').doc(this.selectedTable).collection(docy.data().counter.toString()).doc(itname).update(
                {
                  qnty: this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount + doc.data().unit1,
                  unit1: doc.data().unit1,
                },
              )
          //qntycounters update
              this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount = this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount + doc.data().unit1;
              console.log(JSON.stringify(this.qntycounters));
                        //qntycounters update

            }
      })
    })
      })
  })




}
else{
  console.log("!exists");


  console.log("tablecurrentcounter:"+ this.tablecurrentcounter.toString());
    this.afs.collection('Tables', ref => ref.where('tableid', '==', this.selectedTable)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(docy => {
        this.afs.collection('Items', ref => ref.where('itemid', '==', itmid)).get().toPromise().then(querySnapshot => {
          querySnapshot.forEach(doc => {

            if(!this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid)){

            this.afs.collection('Tables').doc(this.selectedTable).collection(this.tablecurrentcounter.toString()).doc(itname).set(
              {
                itemid: doc.data().itemid,
                itemname: doc.data().itemname,
                qnty: doc.data().unit1,
                unit1: doc.data().unit1,
              },
              { merge: true }
            )
            

          //qntycounters insert
          this.addorreplace(this.qntycounters,{'tid': this.selectedTable,'itemid':itmid, qntycount: doc.data().unit1});
          console.log(JSON.stringify(this.qntycounters));
          //qntycounters insert
            }

            
            else{

              this.afs.collection('Tables').doc(this.selectedTable).collection(docy.data().counter.toString()).doc(itname).update(
                {
                  qnty: this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount + doc.data().unit1,
                  unit1: doc.data().unit1,
                },
              )
          //qntycounters update
              this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount = this.qntycounters.find(item => item.tid == this.selectedTable && item.itemid == itmid).qntycount + doc.data().unit1;
              console.log(JSON.stringify(this.qntycounters));
                        //qntycounters update

            }
      })
    })
      })
  })




}
    })

    .catch(function(error) {
      console.log("Error getting document:", error);
  });
   }

  async submitbtn(){
    let srcCollection = await this.afs.collection('Tables').doc('TABLE 1').collection('1');
    let destCollection = this.afs.collection('Bills').doc('18-04-2021').collection('4');
  
    srcCollection.get().toPromise().then(query => {
  query.forEach(function(doc) {
    destCollection.doc(doc.data().itemid).set(doc.data());
  });
});

}

}
