import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { AnimationDurations } from '@angular/material';
import { firestore } from 'firebase';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-mastermenu',
  templateUrl: './mastermenu.component.html',
  styleUrls: ['./mastermenu.component.css']
})
export class MastermenuComponent implements OnInit {

  public MMForm: FormGroup;
  public SMForm: FormGroup;

  mastermenunew: any;
  submenunew: any;
  mmcollection: any;
  smcollection: any;
  selectedmastermenu: any;
  selectedsubmenu: any;
  selectedmastermenu2: any;
  selectedsubmenu2: any;
  mmcollection2: any;
  smcollection2: any;


  constructor(private afs: AngularFirestore, public fb: FormBuilder) {

   }

  ngOnInit() {

    this.FormMM();
    this.FormSM();

    this.mmcollection =[];
    this.mmcollection = this.afs.collection('Mastermenu').snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
    
    this.smcollection =[];
    this.smcollection = this.afs.collection('Submenu',ref => ref.orderBy('allitems','asc')).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );


    this.mmcollection2 =[];
    this.mmcollection2 = this.afs.collection('Mastermenu').snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

  }

   // Reactive particular form
   FormMM() {
    this.MMForm = this.fb.group({
      newmastermenu: [''],
    })
  }
  // Accessing form control using getters
  get newmastermenu() {
    return this.MMForm.get('newmastermenu');
  }

// Reactive particular form
FormSM() {
  this.SMForm = this.fb.group({
    newsubmenu: [''],
  })
}
// Accessing form control using getters
get newsubmenu() {
  return this.SMForm.get('newsubmenu');
}

mmsubmitbtn(){
  this.afs.collection('Mastermenu').doc(String(this.mastermenunew).toUpperCase()).set(
    {
      mastermenuname : String(this.mastermenunew).toUpperCase()
    },
     {merge: true}
)
}

mmdeletebtn(mmndname){
  this.afs.collection('Mastermenu').doc(mmndname).delete().then(function() {
    console.log("Document successfully deleted!");
}).catch(function(error) {
    console.error("Error removing document: ", error);
});
}

smsubmitbtn(){
  this.afs.collection('Submenu').doc('allitems').set(
    {
      allitems:firestore.FieldValue.arrayUnion(String(this.submenunew).toUpperCase()),
      // committed:'', 
    },
     {merge: true}
)
}

smdeletebtn(smname){
  this.afs.collection('Submenu').doc('allitems').update({
    allitems:firestore.FieldValue.arrayRemove(smname)
  })
}

changeMastermenu(mastermenuselected){
this.selectedmastermenu=mastermenuselected;
}
changeSubmenu(submenuselected){
this.selectedsubmenu=submenuselected;
}

mmsmcommitbtn(mm,ct){

  
  this.afs.collection('MenuSubmenuCounter').get().toPromise().then( querySnapshot => {
    querySnapshot.forEach( doc => {

      let a=doc.data().count;
      console.log(a)

this.afs.collection('Mastermenu').doc(mm).get().toPromise().then(doc => {
if(!doc.data().items){
  this.afs.collection('Mastermenu').doc(mm).update({
    items:firestore.FieldValue.arrayUnion({submenuname:String(ct).toUpperCase(),id:a})
  }).then(()=>{

  this.afs.collection('Submenu').doc('allitems').update({
    committed:firestore.FieldValue.arrayUnion(String(ct).toUpperCase()) 
  }).then(()=>{
  
    this.afs.collection('MenuSubmenuCounter').doc('Counter').update({
      count: firestore.FieldValue.increment(1)
    })
   })
  })

}
var arr=[];    
if(doc.data().items){
  for(let i=0;i<doc.data().items.length;i++){
    if(doc.data().items[i].submenuname == ct){
      arr.push(ct)
      console.log("ALREADY COMMITTED")
    }
    if(doc.data().items[i].submenuname != ct){
    }
}

if(arr.length==0){
this.afs.collection('Mastermenu').doc(mm).update({
  items:firestore.FieldValue.arrayUnion({submenuname:String(ct).toUpperCase(),id:a}) 
}).then(()=>{

this.afs.collection('Submenu').doc('allitems').update({
  committed:firestore.FieldValue.arrayUnion(String(ct).toUpperCase()) 
}).then(()=>{

  this.afs.collection('MenuSubmenuCounter').doc('Counter').update({
    count: firestore.FieldValue.increment(1)
  })
 })
})
console.log("NEWLY COMMITTED")

    }
  }

})

})

  })

}


changeMastermenu2(mastermenuselected){
  this.selectedmastermenu2=mastermenuselected;
    
  this.smcollection2 =[];
  this.smcollection2 = this.afs.collection('Mastermenu',ref => ref.where('mastermenuname', "==" , mastermenuselected)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );

  }

  changeSubmenu2(mastermenuselected,submenuselected){
  this.selectedsubmenu2=submenuselected;

  }



  
}
