import { Component, ViewEncapsulation, OnInit, Input, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { Drawer3Service } from "../../assets/services/drawer3.service";
import { MatCalendarCellCssClasses, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as firebase from 'firebase';
export interface Item { name: string; rate: number;}

import 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-billreturns',
  templateUrl: './billreturns.component.html',
  styleUrls: ['./billreturns.component.css']
})
export class BillreturnsComponent implements AfterViewInit {
  value=0; //progressbar

  message3:boolean;

  selectedDate: string;
  eventdate: string;

  favoriteCustomer: string;
  customers: string[] = ['regular', 'seasonal', 'festival'];
  billoc: string;
  billocations: string[] = ['shop1', 'shop2', 'shop3'];

  taxy:string;
  taxes:string[] = ['gst','igst','taxfree'];

    
  TaxnameGST: string='gst';
  TaxnameIGST: string='0';
  TaxnameTF: string='0';

tottaxableamt: number;
totamtwithtax: number;

sumaftrdisc:number;

totalamount: number;
totalrate: number;
summy: { gst: number, rate: number, taxableamt: number, amtaftdisc:number }[]= [];

  custname: any;
  custaddress11: any;
  custaddress12: any;
  custaddress21: any;
  custaddress22: any;
  custcontact: any;
  custgst: any;
  custhonor: any;
  custaddressS1: any;
  custaddressS2: any;
  sameaddr: boolean;
  prdkeyid: any;
  bcbtndisabled: boolean;
  clrbtndisabled: boolean;
  addbtndisabled: boolean;

  billqnty: any;
  prdqnty: any;
  rembtndisabled: boolean;

  arry:{
    code:string;
    qnty:number;
  }[]=[];

  prdkeyidarray:string[]=[];
  billqntyarray:number[]=[];

  totbills:number;
  crcollection: any;
  
  selectedOptions: string[] = [];
  myary: { billid: any }[]= [] //declare before constructor
  prcollection: any;
  currentbill: any;
  prdcollection: any;

  //qntycounters
qntycounters: { bcid: string, qntycount: number }[]= [] //declare before constructor
//qntycounters

myArray: {amtaftdisc: number, gstp: number, cgstp: number, cgsta: number, sgstp: number, sgsta: number, igstp: number, igsta: number, taxableamt: number, rate: number }[]= [] //declare before constructor

date2= moment().format('DD-MM-YYYY');
currentbillid:any;

custcollection: any;
cust: any;
  
  pcollection: any;

  billdetails:any;
  bcollection: any;
  billno: any;

  constructor(private data3: Drawer3Service, private afs: AngularFirestore) { }

  addorreplace(array, item){
    const i=array.findIndex(_item => _item.billid===item.billid);
    if(i>-1) array[i] =item;
    else array.push(item);
   }
   
  ngAfterViewInit() {
    
    this.btnsenabled();
this.qntycounters=[];
    this.sameaddr=true;

    this.custcollection = this.afs.collection('Customers').snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
    
    this.billno='';
    this.custname='';
  this.custaddress11='';
  this.custaddress12='';
  this.custaddress21='';
  this.custaddress22='';
  this.custcontact='';
  this.custgst='';
  this.custhonor='';
  this.custaddressS1='';
  this.custaddressS2='';


    this.favoriteCustomer=this.customers[3]; // set default radio button checked

    this.selectedDate=moment().format('DD-MM-YYYY')
this.eventdate=moment().format('DD-MM-YYYY')

    this.data3.currentMessage3.subscribe(message3 => this.message3 = message3)
console.log("PL"+this.message3);

this.myary=[];
//this.prcollection =[];
// this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , this.selectedDate))
// .get().toPromise()
// .then(querySnapshot => {
//     querySnapshot.forEach(doc => {
//         // doc.data() is never undefined for query doc snapshots
//         console.log(doc.id, " => ", doc.data());
//         //this.myary.push({'id':doc.data().billsid[0]});
//         for(var i = 0; i < doc.data().totalbills ; i++){
//           this.myary.push({'billid':doc.data().billsid[i]});
//       }
//         console.log("GGGGG: "+JSON.stringify(this.myary));

//     });
// })
// .catch(function(error) {
//     console.log("Error getting documents: ", error);
// });
  
this.bcollection =  this.afs.collection('Bills').doc(this.eventdate).collection('--Id--').snapshotChanges()      
.pipe( 
  map(actions => actions.map(a => a.payload.doc.data()))
);
this.bcollection.subscribe((posts) => {
  this.afs.collection('Bills', ref => ref.where('date2', "==" , this.selectedDate))
.get().toPromise()
.then(querySnapshot => {
    querySnapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        //this.myary.push({'id':doc.data().billsid[0]});
        for(var i = 0; i < doc.data().totalbills ; i++){
          this.addorreplace(this.myary,{'billid':doc.data().billsid[i]});
      }
        console.log("GGGGG: "+JSON.stringify(this.myary));

    });
})
.catch(function(error) {
    console.log("Error getting documents: ", error);
});
})
  }

  btnsdisabled(){
    this.bcbtndisabled=true;
    this.clrbtndisabled=true;
    this.addbtndisabled=true;
    this.rembtndisabled=true;
  }
   btnsenabled(){
    this.bcbtndisabled=null;
    this.clrbtndisabled=false;
    this.addbtndisabled=false; 
    this.rembtndisabled=false;
   }
    
  changeCustomer(ct){
    this.custname=ct.customerName;
    this.custaddress11=ct.customerAddress11;
    this.custaddress12=ct.customerAddress12;
    this.custaddress21=ct.customerAddress21;
    this.custaddress22=ct.customerAddress22;
    this.custcontact=ct.customerContact;
    this.custgst=ct.customerGst;
    this.custhonor=ct.customerHonor;
    this.sameaddr=true;
    console.log("SA"+this.sameaddr)
    this.custaddressS1=this.custaddress11;
    this.custaddressS2=this.custaddress12;

    console.log("cust"+JSON.stringify(this.cust['customerName']))

  }
  
  shipaddress(event){
  console.log(event.checked);
  if(event.checked==true){
    this.custaddressS1=this.custaddress11;
    this.custaddressS2=this.custaddress12;
  }
  if(event.checked==false){
    this.custaddressS1=this.custaddress21;
    this.custaddressS2=this.custaddress22;
  }
  }
  
  onSelect(event){
    
    this.tottaxableamt=0;
    this.totamtwithtax=0; 

    this.sumaftrdisc=0;
    this.totalamount=0;

    console.log(event);
    this.selectedDate = event;
    this.eventdate=moment(event).format('DD-MM-YYYY');
    this.summy=[];
    this.billdetails='';

    this.prdcollection =[];
    this.prdcollection = this.afs.collection('Bills').doc(moment(event).format('DD-MM-YYYY')).collection('1').snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.myary=[];
  this.prcollection =[];
  this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , this.eventdate))
  .get().toPromise()
  .then(querySnapshot => {
      querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          //this.myary.push({'id':doc.data().billsid[0]});
          for(var i = 0; i < doc.data().totalbills ; i++){
            this.myary.push({'billid':doc.data().billsid[i]});
        }
          console.log("GGGGG: "+JSON.stringify(this.myary));

      });
  })
  .catch(function(error) {
      console.log("Error getting documents: ", error);
  });
  
    }


    updateCheckedOptions(tta,i){
      //----newly added----
      this.billno='';
      this.custname='';
      this.custaddress11='';
      this.custaddress12='';
      this.custaddress21='';
      this.custaddress22='';
      this.custcontact='';
      this.custgst='';
      this.custhonor='';
      this.custaddressS1='';
      this.custaddressS2='';
    
      this.billdetails='';
      
      //----newly added----
      tta.checked = !tta.checked;
//this.billdetails=[];
this.pcollection=[];
      this.tottaxableamt=0;
      this.totamtwithtax=0; 

      this.sumaftrdisc=0;
      this.totalamount=0;

      //tta.checked = !tta.checked;
  this.currentbill=tta.billid;
  console.log(this.currentbill);
     console.log("R: "+JSON.stringify(this.myary));
    // return this.myary;
    console.log(this.currentbill.substring(2,3));

    if(String(this.currentbill).slice(0,2)=="S1"){
this.billoc="shop1";
}
else if(String(this.currentbill).slice(0,2)=="S2"){
  this.billoc="shop2";
  }
  else if(String(this.currentbill).slice(0,2)=="S3"){
    this.billoc="shop3";
    }

    if(String(this.currentbill).slice(2,3)=="R"){
      this.favoriteCustomer="regular";
      }
      else if(String(this.currentbill).slice(2,3)=="S"){
        this.favoriteCustomer="seasonal";
        }
        else if(String(this.currentbill).slice(2,3)=="F"){
          this.favoriteCustomer="festival";
          }

            if(String(this.currentbill).slice(3,4)=="G"){
              this.taxy="gst";
              this.TaxnameGST='gst';this.TaxnameIGST='0';this.TaxnameTF='0';
              }
              else if(String(this.currentbill).slice(3,4)=="I"){
                this.taxy="igst";
                this.TaxnameGST='0';this.TaxnameIGST='igst';this.TaxnameTF='0';
                }
                else if(String(this.currentbill).slice(3,4)=="T"){
                  this.taxy="taxfree";
                  this.TaxnameGST='0';this.TaxnameIGST='0';this.TaxnameTF='taxfree';
                  }

                  

  this.afs.collection('Bills').doc(this.eventdate).collection('--Id--',ref => ref.where('currentbillid', '==', Number(tta.billid.substring(4)))).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(docy => {

                  this.billdetails = this.afs.collection('Bills').doc(this.eventdate).collection(String(docy.data().currentbillid)).snapshotChanges()
                  .pipe( 
                    map(actions => actions.map(a => a.payload.doc.data()))
                  );
                
                  this.billdetails.subscribe((posts) => {
                    if(tta.checked==true){

                      this.afs.collection('Bills').doc(this.eventdate).collection('--stats--').doc(String(tta.billid.substring(4))).get().toPromise().then(doc => {
                        this.billno=doc.data().billrefno;
                          this.custhonor = doc.data().customerHonor;
                          this.custname = doc.data().customerName;
                          this.custaddress11= doc.data().customerAddress11;
                          this.custaddress12=doc.data().customerAddress12;
                          this.custaddressS1=doc.data().customerAddress21;
                          this.custaddressS2=doc.data().customerAddress22;
                          this.custcontact=doc.data().customerContact;
                          this.custgst=doc.data().customerGst;
                        })
                      

                    console.log("posty:"+posts); //This is the array of posts, not an observable
                
                      if(tta.billid.substring(3,4)=='G'){
                    this.summy=(_(posts).groupBy('cgst')
                      .map((objs, key) => {
                          return {
                        'billid':tta.billid,
                        'cgst': key,             
                        'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
                        'gstp':(key*2),
                        'cgstp':(parseFloat(key)),
                        'cgsta':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
                        'sgstp':(parseFloat(key)),
                        'sgsta':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
                        'igstp':(parseFloat('0')),
                        'igsta':(parseFloat('0.00')),
                        'taxableamt': 2 * ((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
                          }
                      })
                      .value()
                    );
                
                    this.tottaxableamt=this.summy.reduce((sum1, item1) => sum1 + item1.taxableamt, 0)
                    this.totamtwithtax=this.summy.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0) + this.tottaxableamt;

                    this.sumaftrdisc=this.summy.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0)

                
                    console.log("TAXXXX:"+JSON.stringify(this.totamtwithtax));
                
                      console.log("SUMMYYY:"+JSON.stringify(this.summy));
                    }
                
                      if(tta.billid.substring(3,4)=='I'){
                        this.summy=(_(posts).groupBy('igst')
                        .map((objs, key) => {
                          return {
                              'billid':tta.billid,
                              'igst': key,
                              'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
                              'gstp':(0),
                              'cgstp':(parseFloat('0')),
                              'cgsta':(parseFloat('0.00')),
                              'sgstp':(parseFloat('0')),
                              'sgsta':(parseFloat('0.00')),
                              'igstp':(parseFloat(key)),
                              'igsta':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
                              'taxableamt':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
                               }
                      })
                      .value()
                        );
                    
                        this.tottaxableamt=this.summy.reduce((sum1, item1) => sum1 + item1.taxableamt, 0)
                        this.totamtwithtax=this.summy.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0) + this.tottaxableamt;
                    
                        this.sumaftrdisc=this.summy.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0)

                        console.log("TAXXXX:"+JSON.stringify(this.totamtwithtax));
                    
                          console.log("SUMMYYY:"+JSON.stringify(this.summy));
                        }
                
                        if(tta.billid.substring(3,4)=='T'){
                          this.summy=(_(posts).groupBy('taxfree')
                          .map((objs, key) => {
                            return {
                                'billid':tta.billid,
                                'taxfree': key,
                                'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
                                'gstp':(0),
                                'cgstp':(parseFloat('0')),
                                'cgsta':(parseFloat('0.00')),
                                'sgstp':(parseFloat('0')),
                                'sgsta':(parseFloat('0.00')),
                                'igstp':(parseFloat('0')),
                                'igsta':(parseFloat('0.00')),
                                'taxableamt':(parseFloat('0.00')),
                                 }
                        })
                        .value()
                          );
                      
                          this.tottaxableamt=this.summy.reduce((sum1, item1) => sum1 + item1.taxableamt, 0)
                          this.totamtwithtax=this.summy.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0) + this.tottaxableamt;
                      
                          this.sumaftrdisc=this.summy.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0)

                          console.log("TAXXXX:"+JSON.stringify(this.totamtwithtax));
                          console.log("SUMMYYY:"+JSON.stringify(this.summy));
                
                          }
                
                    console.log("posts:"+posts); //This is the array of posts, not an observable
                
                    let ratetotal = 0;
                    let amounttotal = 0;
                  console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+posts.length);
                  for (var i = 0; i < posts.length; i++) {
                      if (posts[i].rate) {
                        console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+posts[i].rate);
                          ratetotal += posts[i].rate;
                          this.totalrate = ratetotal;
                
                          //amounttotal += (posts[i].rate * posts[i].qnty);
                          amounttotal += (posts[i].rate);
                          this.totalamount = amounttotal;
                      }
                  }
                  console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+ratetotal);
                  return [ratetotal,amounttotal];
                  }
                
                
                  });
                
  
})
})              
                  // this.afs.collection('Bills').doc(this.eventdate).collection('--stats--').doc(tta.billid.substring(4)).get().toPromise().then(doc => {
                  //       if(tta.checked==true){
                  //        console.log("NNNNNN"+doc.data().customerName);
                  //         this.aryaddressdetails.push({'billid':tta.billid,
                  //           customerName: doc.data().customerName,
                  //           customerAddress11: doc.data().customerAddress11,
                  //           customerAddress12: doc.data().customerAddress12,
                  //           customerAddress21: doc.data().customerAddress21,
                  //           customerAddress22: doc.data().customerAddress22,
                  //           customerContact: doc.data().customerContact,
                  //           customerGst: doc.data().customerGst,
                  //           customerType: doc.data().customerType,
                  //         });
                
                  //           console.log("YYYYY"+JSON.stringify(this.aryaddressdetails));
                  //       }
                  //       else {
                  //         console.log("GGG: "+JSON.stringify(this.aryaddressdetails));
                  //         const addrdetailsary =  this.aryaddressdetails.map(function(e) { return e.billid; }).indexOf(tta.billid);
                  //         if (addrdetailsary > -1) {
                  //           this.aryaddressdetails.splice(addrdetailsary, 1);
                  //         }
                  //         console.log("GGGGG: "+JSON.stringify(this.aryaddressdetails));
                  //       }
                        
                  //     return false;         
                
                  // });
                
                
                //----newly added--------
                  tta.checked=true;
                //----newly added--------

                    console.log(tta.checked);
                      
                    this.afs.collection('Bills').doc(this.eventdate).collection('--Id--',ref => ref.where('currentbillid', '==', Number(tta.billid.substring(4)))).get().toPromise().then(querySnapshot => {
                      querySnapshot.forEach(docy => {

                      this.afs.collection('Bills').doc(this.eventdate).collection(String(docy.data().currentbillid)).get().toPromise().then(querySnapshot => {
                      if (!querySnapshot.empty) {
                        querySnapshot.forEach(doc => {
                          // if(tta.checked==true){
                          //  // this.myary.push({'billid':tta.billid});
                          //   this.billdetails.push({'billid':tta.billid,
                          //     'code':doc.data().code,
                          //     'productname': doc.data().productname,
                          //     'hsncode' : doc.data().hsncode,
                          //     'discount' : doc.data().discount,
                          //     'qnty' : doc.data().qnty,
                          //     'unit2' : doc.data().unit2,
                          //     'rate': doc.data().rate,
                          //     'cgst': doc.data().cgst,
                          //     'sgst': doc.data().sgst,
                          //     'igst': doc.data().igst,
                          //   });
                          //   console.log("YYYYY"+JSON.stringify(this.billdetails));

                          // }
                          // else {
                          //   console.log("GGG: "+JSON.stringify(this.arybilldetails));
                          //   const detailsary =  this.arybilldetails.map(function(e) { return e.billid; }).indexOf(tta.billid);
                          //   if (detailsary > -1) {
                          //     this.arybilldetails.splice(detailsary, 1);
                          //   }
                          //   console.log("GGGGG: "+JSON.stringify(this.arybilldetails));
                
                          //   console.log("SSSS: "+JSON.stringify(this.summy));
                          //   const gstdetailsary =  this.summy.map(function(e) { return e.billid; }).indexOf(tta.billid);
                          //   if (gstdetailsary > -1) {
                          //     this.summy.splice(gstdetailsary, 1);
                          //   }
                          //   console.log("SSSS: "+JSON.stringify(this.summy));
                
                          //   console.log("TTTTTTAAAA: "+JSON.stringify(this.tottaxableamt));
                          //   const tottaxableamtdetailsary =  this.tottaxableamt.map(function(e) { return e.billid; }).indexOf(tta.billid);
                          //   if (tottaxableamtdetailsary > -1) {
                          //     this.tottaxableamt.splice(tottaxableamtdetailsary, 1);
                          //   }
                          //   console.log("TTTTTTAAAA: "+JSON.stringify(this.tottaxableamt));
                
                          //   console.log("TTTTTTAAAA: "+JSON.stringify(this.totamtwithtax));
                          //   const totamtwithtaxdetailsary =  this.totamtwithtax.map(function(e) { return e.billid; }).indexOf(tta.billid);
                          //   if (totamtwithtaxdetailsary > -1) {
                          //     this.totamtwithtax.splice(totamtwithtaxdetailsary, 1);
                          //   }
                          //   console.log("TTTTTTAAAA: "+JSON.stringify(this.totamtwithtax));
                
                          // }
                          
                        }) ;
                        return false;         
                      }
                
                    });
                  });

                    });
                  
                   console.log("R: "+JSON.stringify(this.myary));
                   return this.myary;
                  
                  }

                  async delay(ms: number) {
                    await new Promise<void>(resolve => setTimeout(()=>resolve(), ms)).then(()=>{console.log("fired");});
                  }
                  
upcount(itmid: any,itname: any, qnty: any){
  this.btnsdisabled();

  this.value = 10; //progressbar
  this.afs.collection('Items', ref => ref.where('itemid', '==' , itmid)).get().toPromise().then(querySnapshot => {
    //if (querySnapshot.size > 0) {
      console.log(this.currentbill.substring(4))
      console.log(this.eventdate);
      querySnapshot.forEach(doc => {
        if (doc.data().itemid == itmid) {

        this.afs.collection('Bills').doc(this.eventdate).collection('--Id--', ref => ref.where('currentbillid', '==', Number(this.currentbill.substring(4)))).get().toPromise().then(querySnapshot => {
          querySnapshot.forEach(docy => {
        this.afs.collection('Bills').doc(this.eventdate).collection(String(docy.data().currentbillid)).doc(itmid).set(
          {
            rate : (qnty + doc.data().unit1) * (doc.data().rate),
            qnty : qnty + doc.data().unit1,
            amountafterdiscount : ((qnty + doc.data().unit1) * (doc.data().rate - (doc.data().rate * ((doc.data().discount[this.favoriteCustomer])/100)))),
          },
           {merge: true}
      ).then(()=>{
        this.btnsenabled();
      })
        .catch((err) => {
          console.log(err);
        })
      })
    })
      

      }
      })
    

    // } else {
    //   console.log("No such document!");
    // }

  })
  .catch(function(error) {
    console.log("Error getting document: ", error);
  });

  this.value = 100; //progressbar
  this.delay(1000).then(any=>{
    this.value = 0; //progressbar
});

}


downcount(itmid: any,itname: any, qnty: any){
  this.btnsdisabled();

  this.value = 10; //progressbar
  this.afs.collection('Items', ref => ref.where('itemid', '==' , itmid)).get().toPromise().then(querySnapshot => {
    //if (querySnapshot.size > 0) {
      console.log(this.currentbill.substring(4))
      console.log(this.eventdate);
      querySnapshot.forEach(doc => {
        if (doc.data().itemid == itmid) {

        this.afs.collection('Bills').doc(this.eventdate).collection('--Id--', ref => ref.where('currentbillid', '==', Number(this.currentbill.substring(4)))).get().toPromise().then(querySnapshot => {
          querySnapshot.forEach(docy => {
        this.afs.collection('Bills').doc(this.eventdate).collection(String(docy.data().currentbillid)).doc(itmid).set(
          {
            rate : (qnty - doc.data().unit1) * (doc.data().rate),
            qnty : qnty - doc.data().unit1,
            amountafterdiscount : ((qnty - doc.data().unit1) * (doc.data().rate - (doc.data().rate * ((doc.data().discount[this.favoriteCustomer])/100)))),
          },
           {merge: true}
      ).then(()=>{
        this.btnsenabled();
      })
        .catch((err) => {
          console.log(err);
        })
      })
    })
      

      }
      })
    

    // } else {
    //   console.log("No such document!");
    // }

  })
  .catch(function(error) {
    console.log("Error getting document: ", error);
  });

  this.value = 100; //progressbar
  this.delay(1000).then(any=>{
    this.value = 0; //progressbar
});

}


  remove(itmid){
  this.btnsdisabled();

  this.value = 10; //progressbar


    this.afs.collection('Bills').doc(this.eventdate).collection('--Id--', ref => ref.where('currentbillid', '==', Number(this.currentbill.substring(4)))).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(docy => {

    this.afs.collection('Bills').doc(this.eventdate).collection(String(docy.data().currentbillid)).doc(itmid).delete().then(function() {
      console.log("Document successfully deleted!");
  }).catch(function(error) {
      console.error("Error removing document: ", error);
  });

        
})
  }).then(()=>{
    this.btnsenabled();
  
  })

this.value = 100; //progressbar
this.delay(1000).then(any=>{
  this.value = 0; //progressbar
});

}

cleartable(){

  this.btnsdisabled();
  this.value = 10; //progressbar

  this.arry=[];
  var table: HTMLTableElement = <HTMLTableElement> document.getElementById("mytable");
  var rowslength = table.rows.length;
  console.log(rowslength);
  var columnslength = table.rows[2].cells.length // 0 and 1 are header
  console.log(columnslength);

  let sum:number=0;
  for(var i=2; i<rowslength-1; i++){
    
    console.log("qnty:"+table.rows[i].cells[3].innerText.match(/\d/g).join('')); //removes text
    console.log("code:"+table.rows[i].cells[1].innerText); 
this.arry.push({'code':table.rows[i].cells[1].innerText, 'qnty':Number(table.rows[i].cells[3].innerText.match(/\d/g).join(''))});
console.log(this.arry);
  }
  

console.log("LOOOP:"+i);
for(let lo=0;lo<=rowslength-4;lo++){
  
  
this.prdkeyidarray=[];

this.afs.collection('Bills').doc(this.eventdate).collection('--Id--', ref => ref.where('currentbillid', '==', Number(this.currentbill.substring(4)))).get().toPromise().then(querySnapshot => {
  querySnapshot.forEach(docy => {
    
this.afs.collection('Bills').doc(this.eventdate).collection(String(docy.data().currentbillid)).doc(this.arry[lo].code).get().toPromise().then( doc => {
 
  this.billqnty=doc.data().qnty;
  this.prdkeyid=doc.data().KEYID;
this.prdkeyidarray.push(doc.data().KEYID);
console.log("HELLLO"+this.prdkeyidarray.length)


        if (this.billoc == 'shop1') {

for(let z=0;z<this.prdkeyidarray.length;z++){

  this.afs.collection('Products', ref => ref.where('KEYID', '==', this.prdkeyidarray[z]))
  .get().toPromise().then(response => {
    let batch=this.afs.firestore.batch()
    response.docs.forEach((doc) => {
console.log("GGGGGGGG"+doc.data().qntyshop1);
const docRef = firebase.firestore().collection('Products').doc(String(this.prdkeyidarray[z]))
batch.update(docRef,{'qntyshop1': doc.data().qntyshop1+this.arry[z].qnty})
      
})
batch.commit().then(()=>{
console.log("updated"+this.prdkeyid)
})
  
});
}

    this.btnsenabled();
  }
        else if (this.billoc == 'shop2') {
          
for(let z=0;z<this.prdkeyidarray.length;z++){

  this.afs.collection('Products', ref => ref.where('KEYID', '==', this.prdkeyidarray[z]))
  .get().toPromise().then(response => {
    let batch=this.afs.firestore.batch()
    response.docs.forEach((doc) => {
console.log("GGGGGGGG"+doc.data().qntyshop2);
const docRef = firebase.firestore().collection('Products').doc(String(this.prdkeyidarray[z]))
batch.update(docRef,{'qntyshop2': doc.data().qntyshop2+this.arry[z].qnty})
      
})
batch.commit().then(()=>{
console.log("updated"+this.prdkeyid)
})
  
});
}

    this.btnsenabled();
        }

        else if (this.billoc == 'Godown') {          
          for(let z=0;z<this.prdkeyidarray.length;z++){
          
            this.afs.collection('Products', ref => ref.where('KEYID', '==', this.prdkeyidarray[z]))
            .get().toPromise().then(response => {
              let batch=this.afs.firestore.batch()
              response.docs.forEach((doc) => {
          console.log("GGGGGGGG"+doc.data().qntygodown);
          const docRef = firebase.firestore().collection('Products').doc(String(this.prdkeyidarray[z]))
          batch.update(docRef,{'qntygodown': doc.data().qntygodown+this.arry[z].qnty})
                
          })
          batch.commit().then(()=>{
          console.log("updated"+this.prdkeyid)
          })
            
          });
          }
          
              this.btnsenabled();
        }
}).then(()=>{
  for(let x=0;x<this.arry.length;x++){
  this.afs.collection('Bills').doc(this.eventdate).collection(String(docy.data().currentbillid)).doc(String(this.arry[x].code)).delete().then(function() {
    console.log("Document successfully deleted!");
}).catch(function(error) {
    console.error("Error removing document: ", error);
});
  }
})
  })
})

this.value = 100; //progressbar
this.delay(1000).then(any=>{
  this.value = 0; //progressbar
});
}

}

                
                }