import { Component,AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatSortable} from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import 'rxjs';

import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../ledgerdetails/format-datepicker';
import { DatePipe } from '@angular/common';

import * as moment from 'moment';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { LedgerdetailsService } from 'src/assets/services/ledgerdetails.service';

@Component({
  selector: 'app-ledgerdetails',
  templateUrl: './ledgerdetails.component.html',
  styleUrls: ['./ledgerdetails.component.css'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})
export class LedgerdetailsComponent implements AfterViewInit, OnInit {

  panelOpenState = true;

  public fmForm: FormGroup;

  //displayedColumns = ['date', 'name', 'debit', 'credit', 'description', 'edit', 'delete'];
  displayedColumns = ['date2', 'particulartype', 'name', 'debit', 'credit', 'tag', 'description', 'edit', 'delete'];
  //displayedColumns = ['date2', 'name', 'debit', 'credit', 'description'];
  dataSource: MatTableDataSource<any>; 

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  ledkeyid: any;
  leddate: any;
  ledpart: any;
  leddebit: any;
  ledcredit: any;
  leddescription: any="";
  bktag:any;
  ///////filter
  namesearch: any;
  selection: any;
  datesearch: any;
  filterValue = {
    name: '',
    date2s: '',
    date2e: '',
  };
  custcollection: any;
  empcollection: any;
  suppcollection: any;

  tagscollection: any;
  name: any;
  particulartype: string;
  splitty: any=[];

  enableUpdate: boolean = false;

  constructor(private data: LedgerdetailsService, private afs: AngularFirestore, public dialog: MatDialog, public fb: FormBuilder, public datePipe: DatePipe) {   }

  
  ngAfterViewInit() {

    if(this.messageCP=="WAGES"){
      this.afs.collection<any>('LedgerWages',ref=> ref.where('book','==',this.messageCP)).valueChanges().subscribe(data => { //filtered by book
        this.dataSource = new MatTableDataSource(data); 
        this.dataSource.filterPredicate = this.createFilter();
        console.log("FilteresData:" +JSON.stringify(this.dataSource.filteredData));
        this.dataSource.sort = this.sort;
  
        //sorting for date column
        this.dataSource.sortingDataAccessor = (item, property) => {
        //console.log(item)
          switch (property) {
            case 'date2': {
              return (moment(item.date2, "DD-MM-YYYY").toDate());
            }
            default: return item[property];
          }
        };
  
        this.dataSource.paginator=this.paginator;
        //It searches name and description columns. To search in entire table skip it
        //this.dataSource.filterPredicate = (data, filter) =>
        //(data.name.indexOf(filter) !== -1 || data.description.indexOf(filter) !== -1) || (data.date2.indexOf(filter) !== -1);
        //----------------------------------------------------------------------  
        
  
  
      
      })
    }
    else if(this.messageCP=="DISCOUNTS"){
      this.afs.collection<any>('LedgerDiscounts',ref=> ref.where('book','==',this.messageCP)).valueChanges().subscribe(data => { //filtered by book
        this.dataSource = new MatTableDataSource(data); 
        this.dataSource.filterPredicate = this.createFilter();
        console.log("FilteresData:" +JSON.stringify(this.dataSource.filteredData));
        this.dataSource.sort = this.sort;
  
        //sorting for date column
        this.dataSource.sortingDataAccessor = (item, property) => {
        //console.log(item)
          switch (property) {
            case 'date2': {
              return (moment(item.date2, "DD-MM-YYYY").toDate());
            }
            default: return item[property];
          }
        };
  
        this.dataSource.paginator=this.paginator;
        //It searches name and description columns. To search in entire table skip it
        //this.dataSource.filterPredicate = (data, filter) =>
        //(data.name.indexOf(filter) !== -1 || data.description.indexOf(filter) !== -1) || (data.date2.indexOf(filter) !== -1);
        //----------------------------------------------------------------------  
        
  
  
      
      })
    }
    else{
      this.afs.collection<any>('Ledger',ref=> ref.where('book','==',this.messageCP)).valueChanges().subscribe(data => { //filtered by book
        this.dataSource = new MatTableDataSource(data); 
        this.dataSource.filterPredicate = this.createFilter();
        console.log("FilteresData:" +JSON.stringify(this.dataSource.filteredData));
        this.dataSource.sort = this.sort;
  
        //sorting for date column
        this.dataSource.sortingDataAccessor = (item, property) => {
        //console.log(item)
          switch (property) {
            case 'date2': {
              return (moment(item.date2, "DD-MM-YYYY").toDate());
            }
            default: return item[property];
          }
        };
  
        this.dataSource.paginator=this.paginator;
        //It searches name and description columns. To search in entire table skip it
        //this.dataSource.filterPredicate = (data, filter) =>
        //(data.name.indexOf(filter) !== -1 || data.description.indexOf(filter) !== -1) || (data.date2.indexOf(filter) !== -1);
        //----------------------------------------------------------------------  
        
  
  
      
      })
    }
    


  this.tagscollection = this.afs.collection('LedgerBooks', ref => ref.where('bookname', "==" , this.messageCP)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );
  console.log(JSON.stringify(this.tagscollection));

}

getTotalDebit(){
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){let tdebit = _.sumBy(this.dataSource.filteredData, function (a) {
     return parseFloat(a.debit);
});
return tdebit;
}
}


getTotalCredit(){
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){let tcredit = _.sumBy(this.dataSource.filteredData, function (a) {
     return parseFloat(a.credit);
});
return tcredit;
}
}


getTotal(){
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){
let tcreditdebit = _.sumBy(this.dataSource.filteredData, function (a) {  
  if(a.debit>a.credit || a.credit>a.debit){
    return parseFloat(a.debit)-parseFloat(a.credit);
    }
      else{
        return 0.00;
      }
});
return Math.abs(tcreditdebit);
}
}


  applyFilter1(filterValue: string) {
    this.filterValue.name = filterValue.trim().toUpperCase(); 
    //filterValue = filterValue.toLowerCase();
    this.dataSource.filter = JSON.stringify(this.filterValue);  
    console.log(this.dataSource.filter);
    this.getTotalDebit();
    this.getTotalCredit();
    this.getTotal();
  };
  applyFilter2(filterValue: string) {
    //console.log(this.datePipe.transform(this.datesearch.start._d,"dd-MM-yyyy"));
    //console.log(this.datePipe.transform(this.datesearch.end._d,"dd-MM-yyyy"));
    // this.filterValue.date2s = this.datePipe.transform(this.datesearch.start._d,"dd-MM-yyyy"); 
    // this.filterValue.date2e = this.datePipe.transform(this.datesearch.end._d,"dd-MM-yyyy"); 
    this.filterValue.date2s = this.datePipe.transform(new Date(this.datesearch.start),"dd-MM-yyyy"); 
    this.filterValue.date2e = this.datePipe.transform(new Date(this.datesearch.end),"dd-MM-yyyy"); 
    this.dataSource.filter = JSON.stringify(this.filterValue);   
    console.log("S: "+this.filterValue.date2s);
    console.log("E: "+this.filterValue.date2e);
    this.getTotalDebit();
    this.getTotalCredit();
    this.getTotal();
  };

  // createFilter(): (data: any, filter: string) => boolean {
  //   let filterFunction = function(data, filter): boolean {
  //     let searchTerms = JSON.parse(filter);
  //     return (data.name.indexOf(searchTerms.name) !== -1)
  //       && (data.date2 >= (searchTerms.date2s)
  //       && data.date2 <= (searchTerms.date2e))
  //   }
  //   return filterFunction;
  // }


  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function(data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      console.log("CURRDate:" +moment(data.date2, "DD-MM-YYYY").toDate());
      console.log("SDMoment:" +moment(searchTerms.date2s, "DD-MM-YYYY").toDate());
      console.log("EDMoment:" +moment(searchTerms.date2e, "DD-MM-YYYY").toDate());
      if((moment(data.date2, "DD-MM-YYYY").toDate()) >= (moment(searchTerms.date2s, "DD-MM-YYYY").toDate())
      && (moment(data.date2, "DD-MM-YYYY").toDate()) <= (moment(searchTerms.date2e, "DD-MM-YYYY").toDate()))
      {
        console.log(true);
      }
      else{
        console.log(false);
      }
      return (data.name.indexOf(searchTerms.name) !== -1)
        && ((moment(data.date2, "DD-MM-YYYY").toDate()) >= (moment(searchTerms.date2s, "DD-MM-YYYY").toDate())
        && (moment(data.date2, "DD-MM-YYYY").toDate()) <= (moment(searchTerms.date2e, "DD-MM-YYYY").toDate()))
    }
    return filterFunction;
  }

  trackByUid(index, item) {
    return item.uid;
  }


  messageCP: string;

  ngOnInit() {
    this.data.currentMessageCP.subscribe(messageCP => this.messageCP = messageCP)

    this.Form();  

    this.custcollection = this.afs.collection('Customers',ref=> ref.orderBy('customerName')).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
    
    this.empcollection = this.afs.collection('Employees',ref=> ref.orderBy('employeeName')).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.suppcollection = this.afs.collection('Suppliers',ref=> ref.orderBy('supplierName')).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );


    this.sort.sort(<MatSortable>{
      id: 'date2',
      start: 'desc',
    }
  );
  
  }

   
    // Reactive form
    Form() {
      this.fmForm = this.fb.group({
        ledgerdate: [''],
        ledgername: [null, [Validators.required,Validators.minLength(5)]],
        booktag:[''],
        ledgerdebit: [''],
        ledgercredit: [''],
        ledgerdescription: [''],
      })
    }

    // Accessing form control using getters
    get ledgerdate() {
      return this.fmForm.get('ledgerdate');
    }

    get ledgername() {
      return this.fmForm.get('ledgername').setValidators([Validators.required, Validators.minLength(3)]);
    }

    get booktag() {
      return this.fmForm.get('booktag');
    }

    get ledgerdebit() {
      return this.fmForm.get('ledgerdebit');
    }

    get ledgercredit() {
      return this.fmForm.get('ledgercredit');
    }
  
    get ledgerdescription() {
      return this.fmForm.get('ledgerdescription');
    }

    // Reset form's values
    ResetForm() {
      this.fmForm.reset();
      this.enableUpdate=false;
    }
    

    submitLedgerData() {
      this.add(this.fmForm.value.ledgerid);
      this.ResetForm();  // Reset form when clicked on reset button
    };
 

    add(id) {
      if(this.leddebit=="" || this.leddebit==null){this.leddebit=0};
      if(this.ledcredit=="" || this.ledcredit==null){this.ledcredit=0};

      this.splitty=[];
      this.splitty=this.ledpart.split("+");
      this.name = this.splitty[1];
      this.particulartype = this.splitty[2];

      let ids =this.afs.createId();
      if(this.messageCP=="WAGES"){
        this.afs.collection("LedgerWages").doc(ids).set({
          book: this.messageCP,
          KEYID: ids,
          date: this.leddate,
          date2: this.datePipe.transform(this.leddate,"dd-MM-yyyy"),
          part: this.ledpart,
          debit: this.leddebit,
          credit: this.ledcredit,
          description: this.leddescription,
          tag: this.bktag,
          name:this.name,
          particulartype:this.particulartype
        })
          .catch((err) => {
            console.log(err);
          })
      }
      else if(this.messageCP=="DISCOUNTS"){
        this.afs.collection("LedgerDiscounts").doc(ids).set({
          book: this.messageCP,
          KEYID: ids,
          date: this.leddate,
          date2: this.datePipe.transform(this.leddate,"dd-MM-yyyy"),
          part: this.ledpart,
          debit: this.leddebit,
          credit: this.ledcredit,
          description: this.leddescription,
          tag: this.bktag,
          name:this.name,
          particulartype:this.particulartype
        })
          .catch((err) => {
            console.log(err);
          })
      }
      else{        
      this.afs.collection("Ledger").doc(ids).set({
        book: this.messageCP,
        KEYID: ids,
        date: this.leddate,
        date2: this.datePipe.transform(this.leddate,"dd-MM-yyyy"),
        part: this.ledpart,
        debit: this.leddebit,
        credit: this.ledcredit,
        description: this.leddescription,
        tag: this.bktag,
        name:this.name,
        particulartype:this.particulartype
      })
        .catch((err) => {
          console.log(err);
        })
      }
    }

    read(ledg) {  
console.log(this.name);
      this.ledkeyid = ledg.KEYID;
      this.leddate = ((moment(ledg.date2, "DD-MM-YYYY").toDate()));
      console.log(((moment(ledg.date2, "DD-MM-YYYY").toDate())));
      this.ledpart = ledg.part;

      this.splitty=[];
      this.splitty=this.ledpart.split("+");
      // int date=Integer.parseInt(spl[0]);
      // int month=Integer.parseInt(spl[1]);
      this.name = this.splitty[1];
      this.particulartype = this.splitty[2];


      this.leddebit = ledg.debit;
      this.ledcredit = ledg.credit;
      this.leddescription = ledg.description; 
      this.bktag = ledg.tag;
      this.fmForm.get('ledgername').setValue(this.splitty[0]+'+'+this.splitty[1]+'+'+this.splitty[2]);
    }

    
  update(lid) {
    if(this.leddebit=="" || this.leddebit==null){this.leddebit=0};
      if(this.ledcredit=="" || this.ledcredit==null){this.ledcredit=0};
      this.splitty=[];
      this.splitty=this.ledpart.split("+");
      this.name = this.splitty[1];
      this.particulartype = this.splitty[2];
    this.afs.collection("Ledger").doc(lid).update({
        date: this.leddate,
        part: this.ledpart,
        debit: this.leddebit,
        credit: this.ledcredit,
        description: this.leddescription,
        tag: this.bktag,
        name:this.name,
        particulartype:this.particulartype
    }).then(() => {
      console.log('updatedF');
      this.ResetForm();
    })
  }

  
  delete(led) {
    this.afs.collection("Ledger").doc(led.KEYID).delete().then(() => {
      console.log('deleted');
    })
  }

  changeParticular(pt){
    this.splitty=[];
    this.splitty=pt.split("+");
    this.name = this.splitty[1];
    this.particulartype = this.splitty[2];
    console.log(this.splitty)
  }

}