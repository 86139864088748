import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterproduct'
  })
  export class filterproduct implements PipeTransform {

    transform(items: any, vfilter?: any ): any {
      console.log("helllo");
      if(!items || !Object.keys(vfilter).length){
        return items;
      }
      return items.filter(item => {
          return Object.keys(vfilter)
            .filter(_ => vfilter.hasOwnProperty(_))
            .every(key => {
              if(!item[key]) return true; //matches undefined value
              const arrayValues = vfilter[key] as any[];
              return arrayValues.some(_ => _ === item[key]);
            });

      });
  }

  }