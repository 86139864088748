import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class Drawer11Service {

  private messageSource11 = new BehaviorSubject(true); //false is 'default message'
  currentMessage11 = this.messageSource11.asObservable();

  constructor() { }

  changeMessage11(message11: boolean) {
    this.messageSource11.next(message11)
  }

}